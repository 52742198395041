import { resolve } from "path";
import { unlinkSync } from "fs";
import { getCacheByKey, setCacheByKey } from "./getCacheByKey";
import { initializeApollo } from "/lib/apollo-client";
import DESKTOP_STORE_QUERY from "/components/layout/desktop_store.graphql";

export const getConfigDataSync = (store = 'default') => {
  const key = `store_config_${store}.json`;
  const { data } = getCacheByKey(key);
  return data;
};

export const removeConfigData = async (store = 'default') => {
  const key = `store_config_${store}.json`;
  const path = resolve("cache", "jsons", key);
  await unlinkSync(path);
  return getConfigData(store);
};

const getConfigData = async (store = 'default') => {
  const key = `store_config_${store}.json`;
  const { data} = getCacheByKey(key);
  const apolloClient = initializeApollo(null, store)

  if (!data) {
    const store_data =  await apolloClient.query({
      query: DESKTOP_STORE_QUERY,
    })

    return await setCacheByKey(key, store_data?.data, {
      ttl: 86400,
    }).data;
  }

  return data;
};

export default getConfigData;
