// const normalizeSrc = src => {
//     return src.startsWith('/') ? src.slice(1) : src;
// };

export const cloudflareLoader = ({ src, width, quality }) => {
    if(process.env.NEXT_PUBLIC_WIGZO_ENV == 'dev'){
        return src;
    }

    const params = [`width=${width}`,`format=auto`];
    if (quality) {
        params.push(`quality=${quality}`);
    }
    const paramsString = params.join(',');

    if( src.startsWith('/')){
        return `/cdn-cgi/image/${paramsString}/${src.slice(1)}`
    }

    const [subdomain, path] = src.split(".in/");
    return `${subdomain}.in/cdn-cgi/image/${paramsString}/${path}`;
};

