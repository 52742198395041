import { useSelector } from "react-redux"
import { initializeStore } from "/components/store/store"
import { useState } from "react"

export const useResponseMessageBox = () => {
    const [state, setState] = useState({
        open: true,
        vertical: 'top',
        horizontal: 'center',
    });
    const reduxStore = initializeStore()
    const { dispatch } = reduxStore
    const msg = useSelector((state) => state.message)
    const message_type = useSelector((state) => state.message_type)

    const handleSnackClose = () => {
        dispatch(
            {
                type: 'RESPONSE_MSG',
                message: '',
                message_type: '',
            })
        setState({ ...state, open: false });
    };

    return {
        msg,
        message_type,
        handleSnackClose
    }

}