import { gql } from "@apollo/client";
import { initializeApollo } from './apollo-client'
import { IngredientFragment } from "../fragments/ingredient/ingredientFragment";
import { GET_PRODUCT_LIST_QUERY } from "../fragments/productList/productList";

export async function orderList(token, website_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id };

  try {
    const response = await fetch(MAPI_URL + "rest/default/V1/custom/customer-orders/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
    });

    const data = await response.json();
    const resp = (data) ? JSON.parse(data) : []
    return (resp && resp.order) ? resp.order : []
  } catch (error) {
    return error
  }
}

export async function orderListSlug(cookies) {
  const apolloClient = initializeApollo(cookies)

  const { data } = await apolloClient.query({
    query: gql`
    {
      customer {
        orders {
          items {
            increment_id
            id
          }
        }
      }
    }
      `,
  });
  const res = (data.customer.orders.items) ? data.customer.orders.items : null
  return res;
}

export async function orderDetailAPI(token, id, website_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id, order_id: id };
  try {
    const response = await fetch(MAPI_URL + "rest/default/V1/custom/order-detail/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
    });
    const data = await response.json();

    const resp = (data && typeof data == "string") ? JSON.parse(data) : []

    const res = (resp && resp.data && resp.data.customer && resp.data.customer.orders) ? resp.data.customer.orders : []

    return res
  } catch (error) {
    return error
  }
}

export async function getCustomerDataLogin(token) {
  let tokenData = [];
  if (token) {
    tokenData['token'] = token;
  } else {
    return [];
  }
  const apolloClient = initializeApollo(tokenData)
  try {
    const { data, error } = await apolloClient.query({
      query: gql`{
          customer
          {
              firstname
              lastname
              suffix
              email
              customer_number
              verified
              wa_consent
              customerid
              wishlists {
                id
                items_count 
                items_v2 {
                  items {
                    id
                    product {
                      type_id
                      sku
                    }
                  }
                }
              }
          }
        }
        `,
    });
    return data
  } catch (error) {
    return error
  }
}

export async function getCustomerDataCart(apolloClient) {
  try {
    const { data, error } = await apolloClient.query({
      query: gql`{
          customer {
            firstname
            lastname
            suffix
            email
            customer_number
            amaaya_point
            is_subscribed
            wa_consent
            customerid
              verified
            addresses {
              id
              firstname
              lastname
              street
              city
              region {
                region_code
                region
                region_id
              }
              default_billing
              default_shipping
              country_code
              country_label_code
              postcode
              telephone
            }
            wishlists {
              id
              items_count 
              items_v2 {
                items {
                  id
                  product {
                    type_id
                    sku
                  }
                }
              }
            }
          }
        }
        `,
    });

    return data
  } catch (error) {
    console.log('error in fetching customer data', error);
    return error
  }
}
export async function getCustomerData(token, store = null, isOverride = false) {

  let tokenData = [];
  if (token) {
    tokenData['token'] = token;
  } else {
    return [];
  }
  const apolloClient = initializeApollo(tokenData, store, isOverride)
  try {
    const { data, error } = await apolloClient.query({
      query: gql`{
          customer {
            customerid
            firstname
            lastname
            customer_group
            amaaya_point
            customerid
            suffix
            email
            customer_number
            is_subscribed
            wa_consent
              verified
            addresses {
              id
              firstname
              lastname
              street
              city
              region {
                region_code
                region
                region_id
              }
              default_billing
              default_shipping
              country_code
              country_label_code
              postcode
              telephone
            }
            wishlists {
              id
              items_count 
              items_v2 {
                items {
                  id
                  product {
                    type_id
                    sku
                  }
                }
              }
            }
          }
        }
        `,
    });

    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function sendUserOtp(mobile, type = 'LOGIN') {

  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { mobile: mobile, website_id: "1", type };
  try {

    const response = await fetch(MAPI_URL + "rest/default/V1/custom/send-login-otp/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + process.env.MAGENTO_TOKEN
      },
    });

    const data = await response.json();
    return data
  } catch (error) {
    return error
  }
}


export async function sendMobileVerifyOtp(username, website_id, type = 'REGISTER') {
  const response = await fetch("/api/send-otp-verify", {
    method: "POST",
    body: JSON.stringify({ username, website_id, type }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    return data;
  }

  return data;
}


export async function verifyUserMobileOtp(mobile, otp, website_id, accessToken, type = 'REGISTER') {
  const response = await fetch("/api/verify-user-mobile-otp", {
    method: "POST",
    body: JSON.stringify({ mobile, otp, website_id, accessToken, type }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    return data;
  }

  return data;
}
export async function verifyMobileOtp(mobile, otp, website_id, type = 'REGISTER') {
  const response = await fetch("/api/verify-mobile-otp", {
    method: "POST",
    body: JSON.stringify({ mobile, otp, website_id, type }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    return data;
  }

  return data;
}

export async function getCountries(store = '') {
  const apolloClient = initializeApollo(null, store)
  try {
    const { data } = await apolloClient.query({
      query: gql`{
        countries {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
        }
      }
        `,
    });
    return (data) ? data.countries : null
  } catch (error) {
    console.log(error)
    return error
  }
}


export async function getState(id) {
  try {
    const response = await fetch("/api/get-states", {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    if (!response.ok) {
      return data;
    }
    // return data;
    const resp = (data && data.data) ? JSON.parse(data.data) : []
    console.log(resp)
    if (resp.success == true) {
      return resp.data.available_regions
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function getIngredients(sid = null) {
  const id = (sid) ? sid : "3,4,5,6,8,9,10,11,12"
  const apolloClient = initializeApollo()
  try {
    const { data } = await apolloClient.query({
      query: gql`
      query getIngredients($id: String!) {
        Ingredients (
            ingredientids: $id
        ){
          data{
            products
            ...IngredientFragment
          }
        }
      }
      ${IngredientFragment}
        `,
      variables: { id },
    });
    return (data) ? data.Ingredients.data : null
  } catch (error) {
    return error
  }
}

export async function getWishlist(cookies, store) {

  const apolloClient = initializeApollo(cookies, store)
  try {
    const { data } = await apolloClient.query({
      query: gql`{
        customer {
          wishlists {
            id
            items_count
            items_v2 {
              items {
                id
                product {
                  primary_category
                  categories{
                    id
                   url_key
                  }
                  type_id
                  stock_status
                  product_label
                  salableqty{
                    label
                  }
                  sku
                  name
                  url_key
                  url_path
                  pwa_description
                  pwa_how_to_use
                  pwa_clinically_tested
                  pwa_benefits
                  pwa_certifications
                  short_description {
                    html
                  }
                  price{
                    regularPrice{
                      amount{
                      value
                      currency
                      }
                    }
                  }
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                      }
                      final_price {
                        value
                      }
                    }
                  }
                  small_image {
                    url
                  }
                  __typename
                  
                  ... on ConfigurableProduct {
                    configurable_options {
                      id
                      attribute_id
                      label
                      position
                      use_default
                      attribute_code
                      values {
                        value_index
                        label
                      }
                      product_id
                    }
                    variants {
                      product {
                        id
                        name
                        stock_status
                        product_label
                        primary_category
                        salableqty{
                          label
                        }
                        small_image {
                          url
                        }
                        sku
                        price{
                          regularPrice{
                            amount{
                              value
                            }
                          }
                        }
                        price_range {
                          minimum_price {
                            regular_price {
                              value
                            }
                            final_price {
                              value
                            }
                          }
                        }
                        attribute_set_id
                        ... on PhysicalProductInterface {
                          weight
                        }
                       
                      }
                      attributes {
                        
                        label
                        code
                        value_index
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
        `,
      // variables: { id },
    });
    return (data && data.customer.wishlists && data.customer.wishlists[0]) ? data.customer.wishlists[0] : null
  } catch (error) {
    return error
  }
}

export async function checkUserExist(username, website_id, type) {
  const response = await fetch("/api/check-user-exist", {
    method: "POST",
    body: JSON.stringify({ username, website_id, type }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    return data;
  }
  return data;
}

export async function resetPasswordEmailToken(email, website_id) {
  const url = process.env.NEXTAUTH_URL
  const response = await fetch(url + "/api/reset-password-token", {
    method: "POST",
    body: JSON.stringify({ website_id, email }),
    headers: {
      "Content-Type": "application/json"
    },
  });
  const data = await response.json();
  return data;
}

export async function resetPassword(resetToken, newPassword, website_id, otp) {

  const url = process.env.NEXTAUTH_URL
  const response = await fetch(url + "/api/reset-password-otp", {
    method: "POST",
    body: JSON.stringify({ resetToken, newPassword, website_id, otp }),
    headers: {
      "Content-Type": "application/json"
    },
  });
  const data = await response.json();
  return data;
}


export async function rewardHistory(token, mobileno, store) {

  const apolloClient = initializeApollo(token, store)

  try {
    const { data } = await apolloClient.mutate({
      mutation: gql`
        mutation getXenoPointsUserHistory($mobileno: String!) {
          getXenoPointsUserHistory(
                  mobileno: $mobileno
              ) {
                points_balance
                points_delta
                comment
                created_at
            }
        }
      `,
      variables: { mobileno }
    });

    return data?.getXenoPointsUserHistory;
  } catch (error) {
    return error
  }

}

export async function rewardPoints(token, website_id) {

  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id };
  try {

    const response = await fetch(MAPI_URL + "rest/default/V1/custom/reward-points/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
    });

    const data = await response.json();
    return (data) ? JSON.parse(data) : []
  } catch (error) {
    return error
  }
}

export async function getSocialToken(provider, code, website_id, quote_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id, code: code, quote_id: (quote_id != '') ? quote_id : '' };
  const providerUrl = (provider == 'google') ? "google-login" : "fb-login"
  try {
    const response = await fetch(MAPI_URL + "rest/default/V1/custom/" + providerUrl + "/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      },
    });
    const data = await response.json();
    return (data) ? JSON.parse(data) : []
  } catch (error) {
    return error
  }
}


export async function getCustomerFreeTrialProducts(token, website_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id };
  try {

    const response = await fetch(MAPI_URL + "rest/default/V1/custom/freetrial-order-products/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
    });

    const data = await response.json();

    return (data) ? JSON.parse(data) : []
  } catch (error) {
    return error
  }
}

export async function getFreeTrialCounts(website_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id };
  try {

    const response = await fetch(MAPI_URL + "rest/default/V1/custom/store-config/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return (data) ? JSON.parse(data) : []
  } catch (error) {
    return error
  }
}


export async function getEGiftCards(website_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id };
  try {

    const response = await fetch(MAPI_URL + "rest/default/V1/custom/egift-cards/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return (data) ? JSON.parse(data) : []
  } catch (error) {
    return error
  }
}


export async function getTrackOrderData(website_id, email, order_id) {
  const MAPI_URL = process.env.MAGENTO_URL;
  const params = { website_id: website_id, email: email, order_id: order_id };
  try {
    const response = await fetch(MAPI_URL + "rest/default/V1/custom/track-guest-order/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    return (data) ? JSON.parse(data) : []
  } catch (error) {
    return error
  }
}


export async function getBestSellerID(pathname) {
  const apolloClient = initializeApollo()

  try {
    const { data } = await apolloClient.query({
      query: gql`
        query UrlResolver($url: String!) {
          urlResolver(url: $url) {
            id
            type
          }
        }
      `,
      variables: {
        url: pathname,
      },
    })

    return (data && data.urlResolver && data.urlResolver.id) ? data.urlResolver.id : 158
  } catch (error) {
    return error
  }
}

export async function getBestSellerData() {
  const bestSellerID = await getBestSellerID("best-seller.html");
  let productsList = []
  const apolloClient = initializeApollo()
  try {
    const productsListArray = await apolloClient.query({
      query: GET_PRODUCT_LIST_QUERY,
      variables: { filters: { category_id: { eq: bestSellerID } } },
    })
    productsList = (productsListArray.data) ? productsListArray.data : null;

    return productsList
  } catch (error) {
    return []
  }
}


export async function getPaymentMethodList(params) {
  try {
    const MAPI_URL = process.env.MAGENTO_URL;
    const response = await fetch(MAPI_URL + "rest/default/V1/custom/payment_methods/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        'content-type': 'application/json'
      },
    });
    const data = await response.json();
    return (data) ? JSON.parse(data) : []
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function checkPincodeService(pincode) {
  const response = await fetch("/api/check-pincode", {
    method: "POST",
    body: JSON.stringify({ pincode }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (!response.ok) {
    return data;
  }
  return data;
}


export async function getHomePageOrderSKU(token, website_id) {
  try {
    const MAPI_URL = process.env.MAGENTO_URL;
    const params = { website_id: website_id };
    const response = await fetch(MAPI_URL + "rest/default/V1/custom/customer-order-skus/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        'content-type': 'application/json',
        "Authorization": "Bearer " + token
      },
    });
    const data = await response.json();
    return (data) ? data : []
  } catch (error) {
    return error
  }
}

export async function logoutUser(store = '', currency_switch = false) {
  const response = await fetch("/api/logout-user", {
    method: "POST",
    body: JSON.stringify({ store, currency_switch }),
    headers: {
      "Content-Type": "application/json"
    },
  });
  const data = await response.json();
  localStorage.removeItem('login_details')
  return data;
}

export async function checkCart() {
  const url = process.env.NEXTAUTH_URL
  const response = await fetch(`${url}/api/check-cart`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  });
  const data = await response.json();
  return data;
}


