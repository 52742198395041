import { useEffect, useState } from "react";
import { getWebsiteID } from "/lib/common-functions";
import { getReduxSession } from "/lib/sessionHandler";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";

export const useMobileVerifyModal = () => {
    const router = useRouter();
    const [prevURLcookie, setPrevURLcookie] = useCookies(["prevUrl"])
    const [prevURLOLDcookie, setPrevURLOLDcookie] = useCookies(["prevOldUrl"])
    const [cookie, setCookie] = useCookies(["store"])

    const [showLoader, setShowLoader] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const [customerMobile, setCustomerMobile] = useState("");
    const [accessToken, setAccessToken] = useState("");

    let website_id = getWebsiteID(cookie);
    const session = getReduxSession(true);

    let customerNumber = ''

    useEffect(() => {
        if (website_id == 2) {
            setShowForm(false)
            setOpenVerifyModal(false)
        }
    }, [website_id]);

    useEffect(() => {
        if (session && session.user && website_id == 1) {
            const customer = session.user
            customerNumber = (customer && customer.customer_number) ? customer.customer_number : null;
            const verifycustomerNumber = (customer && customer.verified) ? customer.verified : null

            if (verifycustomerNumber != 1 || verifycustomerNumber != '1') {
                setShowForm(true)
                setOpenVerifyModal(true)
                setCustomerMobile(customerNumber)
                setAccessToken(session.accessToken)
            }
        }
    }, [session]);

    useEffect(() => {
        if (prevURLcookie.prevUrl) {
            setPrevURLOLDcookie('prevOldUrl', prevURLcookie.prevUrl, { path: "/", sameSite: true })
        }
        let currentURL = window.location.href
        if (!currentURL.includes('account')) {
            setPrevURLcookie('prevUrl', window.location.href, { path: "/", sameSite: true })
        }
    }, [router.asPath]);

    return {
        showForm,
        showLoader,
        openVerifyModal,
        accessToken,
        customerMobile,
        setShowLoader,
        setOpenVerifyModal
    }
}
