import React, { useState, useContext, createContext } from 'react'


const authContext = createContext()

export function AuthProvider({ children }) {
  const auth = useProvideAuth()

  return (
    <authContext.Provider value={auth}>
     {children}
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const [authToken, setAuthToken] = useState(null)

  const isSignedIn = () => {
    if (authToken) {
      return true
    } else {
      return false
    }
  }


  const login = async (result) => {

    if (result) {
      setAuthToken(result)
    }
  }

  const logout = () => {
    setAuthToken(null)
  }

  return {
    setAuthToken,
    isSignedIn,
    login,
    logout,
  }
}
