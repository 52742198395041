import { getVideoStatus } from "../services/helpers/getVideoStatus";
import { getUnderScoreSepratedString } from "../services/helpers/stringHelper";

// Log events using GTM
export const gtm = (gtmData) => {
  try {
    console.log('_GTM', gtmData)
    window["dataLayer"].push(gtmData);
  } catch (e) {
    console.log('dataLayer', e)
  }
};

// log the pageview with their URL
export const pageview = (url) => {
  try {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID, {
      page_path: url,
    })
  } catch (error) {
    console.log('error', error);
  }
}

// log specific events happening.
export const event = ({ action, params }) => {
  try {
    window.gtag('event', action, params)
  } catch (error) {
    console.log('error', error);
  }
}

// log specific events happening.
export const EIevent = (url) => {
  // if(url!='/checkout/success'){
  //   !function(key,gid,uid,eid,rev){var
  //     n,s,i,g,o,c;n=window,s=document,i="script",g="ga",n.GoogleAnalyticsObject=g,n.ga=n.ga||
  //     function(){(n.ga.q=n.ga.q||[]).push(arguments)},n.ga.l=1*new
  //     Date,o=s.createElement(i),c=s.getElementsByTagName(i)[0],o.async=1,
  //     o.src="https://www.googleanalytics.com/analytics.js",c.parentNode.insertBefore(o,c),ga("create",gid,"auto","ei");
  //     var d=document.cookie.match("(^|;)\\s*_fbp\\s*=\\s*([^;]+)"),p=d?d.pop():"";
  //     var f=document.cookie.match("(^|;)\\s*_fbc\\s*=\\s*([^;]+)"),q=f?f.pop():"";
  //     ga("ei.set","sendHitTask",function(e){var a=new
  //     XMLHttpRequest;a.open("POST","https://d.easyinsights.in/ga/"+key,!0),a.send(JSON.stringify({q:
  //     e.get("hitPayload")}))}),ga("ei.set","dimension16",uid),ga("ei.set","dimension17",p),
  //     ga("ei.set","dimension18",eid),ga("ei.set","dimension19",rev),ga("ei.set","dimension20",q),
  //     ga("ei.send","pageview")}("1693eb5aa9b78278a37facce6b922ecb", "UA-59626779-1", "", "", "");
  // }  
}


// log specific events happening.
export const Optimiseevent = (url) => {
  if (url != '/checkout/success') {
    OMID = 2318088; OPID = 52364; ORef = escape(window.parent.location.href); !function () { var a = document.createElement("script"); a.type = "text/javascript", a.async = !0, a.src = "//track.omguk.com/e/qs/?action=Content&MID=" + OMID + "&PID=" + OPID + "&ref=" + ORef; var b = document.getElementsByTagName("body")[0]; if (b) b.appendChild(a, b); else { var b = document.getElementsByTagName("script")[0]; b.parentNode.insertBefore(a, b) } }();
  }
}

export const TopOfferBar = (offerDetail, eventCommonObj) => {
  gtm({ ...offerDetail, ...eventCommonObj })
}


export const TopNavigationClick = (elementName, eventCommonObj) => {
  gtm({
    event: 'top_navigation_click',
    element_name: elementName || 'N/A',
    ...eventCommonObj
  })
}

export const ProfileOptinsClick = (elementName, eventCommonObj) => {
  gtm({
    event: 'profile_options_click',
    element_name: elementName || 'N/A',
    ...eventCommonObj
  })
}

export const HeaderClick = (elementName, eventCommonObj) => {
  gtm({
    event: 'mega_menu_click',
    mega_menu: getUnderScoreSepratedString(elementName) || 'N/A',
    ...eventCommonObj
  })
}

export const HeaderOptionClick = (elementName, elementChildrenName, elementSubName, eventCommonObj) => {
  gtm({
    event: 'mega_menu_option_click',
    mega_menu: getUnderScoreSepratedString(elementName) || 'N/A',
    mega_menu_option: getUnderScoreSepratedString(elementChildrenName) || 'N/A',
    element_name: getUnderScoreSepratedString(elementSubName) || 'N/A',
    ...eventCommonObj
  })
}

export const SubscribePopUp = (eventCommonObj) => {
  gtm({
    event: 'subscribe_click',
    ...eventCommonObj
  })
}

export const ClosePopupClick = (headerName, eventCommonObj) => {
  gtm({
    event: 'close_popup_click',
    header_name: headerName || 'N/A',
    ...eventCommonObj
  })
}

export const CallAssistanceInitiate = (eventCommonObj) => {
  gtm({
    event: 'call_assistance_initiate',
    ...eventCommonObj
  })
}

export const CallAssistanceSuccess = (eventCommonObj) => {
  gtm({
    event: 'call_assistance_successful',
    ...eventCommonObj
  })
}

export const ChatAssistanceInitiate = (eventCommonObj) => {
  gtm({
    event: 'chat_assistance_initiate',
    ...eventCommonObj
  })
}

export const ViewAllClick = (headerName, eventCommonObj) => {
  gtm({
    event: 'view_all_click',
    header_name: getUnderScoreSepratedString(headerName) || 'N/A',
    ...eventCommonObj
  })
}

export const ViewAllCategoryClick = (headerName, eventCommonObj) => {
  gtm({
    event: 'view_all_category_click',
    header_name: getUnderScoreSepratedString(headerName) || 'N/A',
    ...eventCommonObj
  })
}

export const BookFreeSession = (headerName, eventCommonObj) => {
  gtm({
    event: 'book_your_free_session',
    header_name: headerName || 'N/A',
    ...eventCommonObj
  })
}

export const ReadKamaBlog = (eventCommonObj) => {
  gtm({
    event: 'read_kama_blogs',
    ...eventCommonObj
  })
}

export const EnquireNowClick = (headerName, eventCommonObj) => {
  gtm({
    event: 'enquire_now_click',
    header_name: headerName || 'N/A',
    ...eventCommonObj
  })
}

export const ExploreAllBlogs = (headerName, eventCommonObj) => {
  gtm({
    event: 'explore_all_blogs',
    header_name: headerName || 'N/A',
    ...eventCommonObj
  })
}

export const ReadMoreClick = (blogName, headerName, eventCommonObj) => {
  gtm({
    event: 'read_more_click',
    blog_name: getUnderScoreSepratedString(blogName) || 'N/A',
    header_name: getUnderScoreSepratedString(headerName) || 'N/A',
    ...eventCommonObj
  })
}

export const BottomNavigationLink = (headerName, elementName, socialIcon, eventCommonObj) => {
  gtm({
    event: 'bottom_navigation_click',
    header_name: headerName || 'N/A',
    element_name: elementName || 'N/A',
    social_icon: socialIcon || 'N/A',
    ...eventCommonObj
  })
}

export const HamburgerOptionClick = (megaMenu, megaMenuOption, elementName, socialIcon, eventCommonObj) => {
  gtm({
    event: 'hamburger_option_click',
    mega_menu: getUnderScoreSepratedString(megaMenu) || 'N/A',
    mega_menu_option: getUnderScoreSepratedString(megaMenuOption) || 'N/A',
    element_name: getUnderScoreSepratedString(elementName) || 'N/A',
    social_icon: socialIcon || 'N/A',
    ...eventCommonObj
  })
}

export const SignUpInitiate = (signUpMethod, eventCommonObj) => {
  gtm({
    event: 'sign_up_initiate',
    sign_up_method: signUpMethod,
    ...eventCommonObj
  })
}

export const SignUpSendOtp = (eventCommonObj) => {
  gtm({
    event: 'sign_up_send_otp',
    ...eventCommonObj
  })
}


export const LoginSuccess = (loginMethod, eventCommonObj) => {
  let login_method;
  if (loginMethod.includes('@')) {
    login_method = 'email'
  } else {
    login_method = 'mobile_number'
  }

  gtm({
    event: 'login_successful',
    login_method: login_method || loginMethod,
    ...eventCommonObj
  })
}


export const SignUpReSendOtp = (eventCommonObj) => {
  gtm({
    event: 'sign_up_resend_otp',
    ...eventCommonObj
  })
}

export const VideoIconClick = (productName, productCategory, platform, eventCommonObj) => {
  gtm({
    event: 'video_icon_click',
    product_name: productName,
    product_category: productCategory,
    platform: platform,
    ...eventCommonObj
  })
}

export const KnowMoreAmayaClick = (productName, eventCommonObj) => {
  gtm({
    event: 'know_more_click',
    product_name: getUnderScoreSepratedString(productName),
    ...eventCommonObj
  })
}

export const ShopNowClick = (product, eventCommonObj) => {
  let categoryName = '';
  if (product?.categories?.length >= 2) {
    categoryName = product?.categories[product?.categories?.length - 2]?.name;
  } else if (product?.categories?.length == 1) {
    categoryName = product?.categories[0]?.name;
  } else {
    categoryName = 'N/A'
  }

  gtm({
    event: 'shop_now_click',
    product_name: product?.name,
    product_category: categoryName || 'N/A',
    ...eventCommonObj
  })
}

export const CheckPincodeClick = (eventCommonObj) => {
  gtm({
    event: 'check_click',
    ...eventCommonObj
  })
}

export const ShareClick = (productName, productCategory, eventCommonObj) => {
  gtm({
    event: 'share_click',
    product_name: getUnderScoreSepratedString(productName) || 'N/A',
    product_category: getUnderScoreSepratedString(productCategory) || 'N/A',
    ...eventCommonObj
  })
}

export const ProductReviewSubmit = (productName, productCategory, rating, productSummary, eventCommonObj) => {
  gtm({
    event: 'product_review_submit',
    product_name: getUnderScoreSepratedString(productName) || 'N/A',
    product_category: getUnderScoreSepratedString(productCategory) || 'N/A',
    rating,
    product_summary: productSummary || 'N/A',
    ...eventCommonObj
  })
}

export const ApplyCouponClick = (couponCode, eventCommonObj) => {
  gtm({
    event: 'apply_coupon',
    coupon_code: couponCode || 'N/A',
    ...eventCommonObj
  })
}

export const EditBag = (product, eventCommonObj) => {
  const productName = product?.map((item) => {
    return item?.product?.name?.toLowerCase()?.split(' ')?.join('_')
  })?.join(' | ')

  const productCategory = product?.map((item) => {
    if (item?.product?.primary_category) {
      return item?.product?.primary_category?.toLowerCase()?.split(' ')?.join('_')
    }
  })?.filter(Boolean)?.join(' | ')

  gtm({
    event: 'edit_bag',
    product_name: productName || 'N/A',
    product_category: productCategory || 'N/A',
    ...eventCommonObj
  })
}

export const RemoveFromWishlist = (productcategory, productName, eventCommonObj) => {
  gtm({
    event: 'remove_from_wishlist',
    product_name: getUnderScoreSepratedString(productName) || 'N/A',
    product_category: getUnderScoreSepratedString(productcategory) || 'N/A',
    ...eventCommonObj
  })
}

export const BlogPageInteraction = (elementName, blogName, blogCategory, authorName, eventCommonObj) => {
  gtm({
    event: 'blog_page_interaction',
    element_name: elementName || 'N/A',
    blog_name: getUnderScoreSepratedString(blogName) || 'N/A',
    blog_category: getUnderScoreSepratedString(blogCategory) || 'N/A',
    author_name: getUnderScoreSepratedString(authorName) || 'N/A',
    ...eventCommonObj
  })
}

export const StoreLocationClick = (elementName, storeName, region, eventCommonObj) => {
  gtm({
    event: 'store_location',
    element_name: elementName || 'N/A',
    store_name: getUnderScoreSepratedString(storeName) || 'N/A',
    region: getUnderScoreSepratedString(region) || 'N/A',
    ...eventCommonObj
  })
}

export const MobileFieldCheckout = (eventCommonObj) => {
  gtm({
    event: 'mobile_form_field',
    ...eventCommonObj
  })
}

export const EmailFieldCheckout = (eventCommonObj) => {
  gtm({
    event: 'email_form_field',
    ...eventCommonObj
  })
}


export const FirstNameFieldCheckout = (eventCommonObj) => {
  gtm({
    event: 'first_name_form_field',
    ...eventCommonObj
  })
}

export const LastNameFieldCheckout = (eventCommonObj) => {
  gtm({
    event: 'last_name_form_field',
    ...eventCommonObj
  })
}


export const PostCodeFieldCheckout = (eventCommonObj) => {
  gtm({
    event: 'pincode_form_field',
    ...eventCommonObj
  })
}


export const AddressFieldCheckout = (eventCommonObj) => {
  gtm({
    event: 'address_form_field',
    ...eventCommonObj
  })
}


// Standard GA4 Events

export const ProductClicks = (product, session = '', websiteID = '', variantLabel = '', eventCommonObj, ItemCategory3 = '', item_list_index = '', item_list_name = '', item_list_id = '') => {
  try {
    window["dataLayer"].push({ ecommerce: null });
    const discountAmount = product?.price_range ? product?.price_range?.minimum_price?.regular_price?.value - product?.price_range?.minimum_price?.final_price?.value : product?.mprice - product?.price
    const getCoupon = localStorage.getItem('coupon_applied')

    const videoClickedStatus = getVideoStatus(product?.id)

    let itemIndex;
    if (item_list_index != '') {
      itemIndex = item_list_index == 0 ? 1 : item_list_index + 1
    } else {
      itemIndex = product?.item_list_index == 0 ? 1 : product?.item_list_index + 1
    }

    gtm({
      event: 'select_item',
      item_list_name: item_list_name || product?.item_list_name || 'N/A',
      item_list_id: product?.item_list_id || item_list_id || 'N/A',
      ecommerce: {
        items: [
          {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: getCoupon || 'N/A',
            currency: websiteID || product?.storeid == 1 ? 'INR' : 'USD',
            discount: discountAmount || 0,
            item_category: product?.primaryCategory || product?.primary_category || product?.variants?.[0]?.product?.primary_category || 'N/A',
            item_category2: product?.productLabel || product?.product_label || 'N/A',
            item_category3: ItemCategory3 || 'N/A',
            item_category4: product?.stock || product?.stock_status || 'N/A',
            item_variant: variantLabel || `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || product?.variants[0]?.product?.size?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: (product?.storeid == 1 || websiteID == 1) ? 'KAMA' : 'KAMA International', // Need to confirm
            price: product?.price_range?.minimum_price?.regular_price?.value || product?.mprice || product?.price?.regularPrice?.amount?.value || product?.price || '',
            item_list_name: item_list_name || product?.item_list_name || 'N/A', // Data??
            item_list_id: product?.item_list_id || item_list_id || 'N/A', // Data??
            index: itemIndex || 'N/A', // Data??
            quantity: 1,
            video_clicked_status: videoClickedStatus || 'No',
          }
        ]
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('ProductClicks GA Event Error: ', error);
  }
}

export const ViewProduct = (product, selectSku = '', variantLabel = '', session, websiteID, eventCommonObj) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    const discountAmount = product?.price_range ? product?.price_range?.minimum_price?.regular_price?.value - product?.price?.regularPrice?.amount?.value : product.mprice - product.price
    const getCoupon = localStorage.getItem('coupon_applied')

    gtm({
      event: 'view_item',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: product?.price_range?.minimum_price?.regular_price?.value || product?.price.regularPrice.amount.value || product?.mprice || product?.price,
        items: [
          {
            item_id: selectSku || product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: getCoupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: discountAmount || 0,
            item_category: product?.primary_category || product?.primaryCategory || product?.variants?.[0]?.product?.primary_category || 'N/A',
            item_category2: product?.product_label || 'N/A',
            item_category3: 'N/A',
            item_category4: product?.stock_status || product?.stock || 'N/A',
            item_variant: variantLabel || `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || product?.variants[0]?.product?.size?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            price: product?.price_range?.minimum_price?.regular_price?.value || product?.price.regularPrice.amount.value || product?.mprice || product?.price,
            item_list_name: 'N/A', // It will be NA in case of PDP
            item_list_id: 'N/A', // It will be NA in case of PDP
            index: 'N/A', // It will be NA in case of PDP
            quantity: 1,
          }
        ]
      },
      ...eventCommonObj
    })
  } catch (error) {
    console.error('ViewProduct GA Event error: ', error);
  }
}

export const AddToWishlistEvent = (product, sku = '', variantLabel = '', session, websiteID, eventCommonObj, ItemCategory3 = '', productPrice = '', productSellPrice = '') => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    const discountAmount = productPrice - productSellPrice ?? product.mprice - product.price
    const product_category = product?.primaryCategory || product?.variants?.[0]?.product?.primary_category || null
    const getCoupon = localStorage.getItem('coupon_applied')

    gtm({
      event: 'add_to_wishlist',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: productPrice || product?.mprice || product?.price?.regularPrice?.amount?.value || product?.price,
        items: [
          {
            item_id: sku || product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: getCoupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: discountAmount || 0,
            price: productPrice || product?.mprice || product?.price?.regularPrice?.amount?.value || product?.price,
            item_category: product?.primary_category || product_category || 'N/A',
            item_category2: product?.product_label || product?.productLabel || 'N/A',
            item_category3: ItemCategory3 || 'N/A',
            item_category4: product?.stock || product?.stock_status || 'N/A',
            item_variant: variantLabel || `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || product?.variants[0]?.product?.size?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            quantity: 1,
          }
        ]
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('AddToWishlistEvent GA Event error: ', error)
  }
}

export const AddToBagEvent = (product, productFinalPrice = '', productRegularPrice = '', session, variantLabel = '', sku = '', quantity = 1, websiteID = '', eventCommonObj, ItemCategory3 = '') => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    const discountAmount = productRegularPrice - productFinalPrice
    const getCoupon = localStorage.getItem('coupon_applied')

    gtm({
      event: 'add_to_cart',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: productRegularPrice || productFinalPrice || product?.price?.regularPrice?.amount?.value || product?.mprice || product?.price || 'N/A',
        items: [
          {
            item_id: sku || product?.sku,
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: getCoupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: discountAmount || 0,
            item_category: product?.primary_category || product?.primaryCategory || product?.variants?.[0]?.product?.primary_category || 'N/A',
            item_category2: product?.product_label || product?.productLabel || 'N/A',
            item_category3: ItemCategory3 || 'N/A',
            item_category4: product?.stock || product?.stock_status || 'N/A',
            item_variant: variantLabel || `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || product?.variants[0]?.product?.size?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            price: productRegularPrice || productFinalPrice || product?.price?.regularPrice?.amount?.value || product?.mprice || product?.price || 'N/A',
            quantity: quantity,
          }
        ]
      },
      ...eventCommonObj
    })
  } catch (error) {
    console.error('AddToBagEvent GA Event error: ', error);
  }
}

// Format completely different from normal addtobag
export const NewAddToBagEvent = (productDetails, couponName = '', session, websiteID = '', eventCommonObj, quantity = '') => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    const discountAmount = Number(productDetails?.productprice) - Number(productDetails?.prices?.price?.value)

    const product = {
      sku: productDetails?.product?.sku || '',
      name: productDetails?.product?.name || '',
      discountAmount: discountAmount || 0,
      variants: productDetails?.size ? [{ size: productDetails?.size }] : 'N/A',
      price: productDetails?.prices?.price?.value || productDetails?.productprice,
      quantity: productDetails?.quantity || 1,
      productCategory: productDetails?.product?.primary_category || 'N/A',
      productLabel: productDetails?.product?.product_label || 'N/A'
    }

    const variantLabel = productDetails?.size || 'N/A';
    const productprice = productDetails?.productprice || productDetails?.prices?.price?.value

    gtm({
      event: 'add_to_cart',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: productDetails?.freeproduct == true ? 0 : productprice || product?.price || 'N/A',
        items: [
          {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: couponName || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: productDetails?.freeproduct == true ? 0 : product.discountAmount,
            item_category: product?.productCategory || 'N/A', // Not getting data
            item_category2: product?.productLabel || 'N/A',
            item_category3: 'N/A',
            item_category4: productDetails?.freeproduct == true ? 'complimentary_product' : 'IN_STOCK',
            item_variant: variantLabel || `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            price: productDetails?.freeproduct == true ? 0 : productprice || product?.price || 'N/A',
            quantity: quantity || product?.quantity,
          }
        ]
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('RemoveFromBagEvent GA Event error: ', error);
  }
}

// Format completely different from normal addtobag
export const AddtoBagOfferEvent = (eventProducts, coupon = '', website_id, eventCommonObj) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'add_to_cart',
      currency: website_id == 1 ? 'INR' : 'USD',
      ecommerce: {
        items: eventProducts?.map((product, i) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: coupon || 'N/A',
            currency: website_id == 1 ? 'INR' : 'USD',
            discount: product?.price_range ? product?.price_range?.minimum_price?.regular_price?.value - product?.price_range?.minimum_price?.final_price?.value : product.mprice - product.price || 'N/A',
            price: product?.price_range?.minimum_price?.regular_price?.value || product?.price?.regularPrice?.amount?.value || product?.mprice || product?.price?.value, // Need to confirm
            item_category: product?.primary_category || 'N/A', // Not getting data
            item_category2: product?.product_label || 'N/A',
            item_category3: 'N/A',
            item_category4: 'IN_STOCK',
            item_variant: product?.size?.label || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${website_id == 1 ? 'KAMA' : 'KAMA International'}`, // Need to confirm
            quantity: product?.qty || 1
          }
        })

      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('AddShippingInfo GA Event error: ', error);
  }
}

export const RemoveFromBagEvent = (productDetails, couponName = '', session, websiteID = '', eventCommonObj, quantity = '') => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    const discountAmount = Number(productDetails?.productprice) - Number(productDetails?.prices?.price?.value)

    const product = {
      sku: productDetails?.product?.sku || '',
      name: productDetails?.product?.name || '',
      discountAmount: discountAmount || 0,
      variants: productDetails?.size ? [{ size: productDetails?.size }] : 'N/A',
      price: productDetails?.prices?.price?.value || productDetails?.productprice,
      quantity: quantity || productDetails?.quantity || 1,
      productCategory: productDetails?.product?.primary_category || 'N/A',
      productLabel: productDetails?.product?.product_label || 'N/A'
    }

    const variantLabel = productDetails?.size || 'N/A';
    const productprice = productDetails?.productprice || productDetails?.prices?.price?.value

    gtm({
      event: 'remove_from_cart',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: productDetails?.freeproduct == true ? 0 : productprice || product?.price || 'N/A',
        items: [
          {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: couponName || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: productDetails?.freeproduct == true ? 0 : product.discountAmount,
            item_category: product?.productCategory || 'N/A', // Not getting data
            item_category2: product?.productLabel || 'N/A',
            item_category3: 'N/A',
            item_category4: productDetails?.freeproduct == true ? 'complimentary_product' : 'IN_STOCK',
            item_variant: variantLabel || `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || product?.variants[0]?.product?.size?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            price: productDetails?.freeproduct == true ? 0 : productprice || product?.price || 'N/A',
            quantity: quantity || product?.quantity,
          }
        ]
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('RemoveFromBagEvent GA Event error: ', error);
  }
}

export const ViewCartEventOnCartPage = (product, grand_total = '', couponName = '', session, websiteID, eventCommonObj) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'view_cart',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: grand_total || '',
        items: product?.map((item) => (
          {
            item_id: item?.product?.sku || '',
            item_name: item?.product?.name || '',
            affiliation: 'Online Store',
            coupon: couponName || product?.applied_coupons || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: item.freeproduct == true ? 0 : item.productprice - item?.prices?.price?.value || 0,
            item_category: item?.product?.primary_category || 'N/A', // Not getting data
            item_category2: item?.product?.product_label || 'N/A',
            item_category3: 'N/A',
            item_category4: item?.freeproduct == true ? 'complimentary_product' : 'IN_STOCK',
            item_variant: item?.size || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            price: item.freeproduct == true ? 0 : item.productprice || item?.prices?.price?.value || 'N/A',
            quantity: item?.quantity || '',
          }
        ))
      },
      ...eventCommonObj
    })
  } catch (error) {
    console.error('ViewCartEventOnCartPage GA Event error:', error);
  }
}

export const BeginCheckout = ({
  products,
  coupon,
  totalCartValue,
  session,
  websiteID,
  eventCommonObj,
  checkout_cta
}) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'begin_checkout',
      coupon,
      checkout_cta_click: checkout_cta || 'checkout',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: totalCartValue,
        coupon,
        items: products.map((product, i) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: coupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: product?.freeproduct == true ? 0 : product?.productOriginalPrice - product?.item_price || 0,
            item_category: product?.category || 'N/A', // Not getting data
            item_category2: product?.productLabel || 'N/A',
            item_category3: 'N/A',
            item_category4: product?.freeproduct == true ? 'complimentary_product' : product?.stock_status || 'N/A',
            item_variant: product?.size || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`, // Need to confirm
            price: product?.freeproduct == true ? 0 : product?.productOriginalPrice || product.item_price, // Need to confirm
            quantity: product.quantity
          }
        })

      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('BeginCheckout Event Error: ', error);
  }
}

export const AddShippingInfo = ({ products, coupon, shipping_tier, totalCartValue, session, websiteID, eventCommonObj }) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'add_shipping_info',
      ecommerce: {
        currency: websiteID == 1 ? 'INR' : 'USD',
        value: totalCartValue,
        coupon,
        shipping_tier,
        items: products.map((product, i) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: coupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: product?.freeproduct == true ? 0 : product?.productOriginalPrice - product?.item_price || 0,
            price: product?.freeproduct == true ? 0 : product?.productOriginalPrice || product.item_price, // Need to confirm
            item_category: product?.category || 'N/A', // Not getting data
            item_category2: product?.productLabel || 'N/A',
            item_category3: 'N/A',
            item_category4: product?.freeproduct == true ? 'complimentary_product' : product?.stock_status || 'N/A',
            item_variant: product?.size || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`, // Need to confirm
            quantity: product?.quantity || 1
          }
        })

      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('AddShippingInfo GA Event error: ', error);
  }
}

export const AddPaymentInfo = ({ products, payment_type, coupon, value, session, websiteID, eventCommonObj }) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'add_payment_info',
      ecommerce: {
        payment_type,
        currency: websiteID == 1 ? 'INR' : 'USD',
        value,
        coupon,
        items: products.map((product, i) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: coupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: product?.freeproduct == true ? 0 : product?.productOriginalPrice - product?.item_price || 0,
            price: product?.freeproduct == true ? 0 : product?.productOriginalPrice || product.item_price, // Need to confirm
            item_category: product?.category || 'N/A', // Not getting data ( once primary_category added we can pass this)
            item_category2: product?.productLabel || 'N/A', // sub category data not present
            item_category3: 'N/A', // applied filter data not present
            item_category4: product?.freeproduct == true ? 'complimentary_product' : product?.stock_status || 'N/A',
            item_variant: product?.size || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`, // Need to confirm
            quantity: product?.quantity || 1
          }
        })

      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('AddPaymentInfo GA Event error: ', error);
  }
}

export const PurchaseEvent = ({ newProducts, payment_type, transaction_id, tax, shipping, coupon, value, websiteID, eventCommonObj }) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'purchase',
      ecommerce: {
        payment_type,
        transaction_id,
        value,
        tax,
        shipping,
        currency: websiteID == 1 ? 'INR' : 'USD',
        coupon,
        affiliation: 'Online Store',
        items: newProducts.map((product, i) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: coupon || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: product?.regular_price - product?.final_price || 0,
            price: product?.freeproduct == true ? product?.price || 0 : product?.regular_price || 'N/A',
            item_category: product?.primary_category || 'N/A', // Not getting data ( once primary_category added we can pass this)
            item_category2: product?.product_label || 'N/A', // sub category data not present
            item_category3: 'N/A', // applied filter data not present
            item_category4: product?.freeproduct == true ? 'complimentary_product' : 'IN_STOCK',
            item_variant: product?.size || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`, // Need to confirm
            quantity: Number(product?.quantity) || 1
          }
        })
      },
      ...eventCommonObj
    })
  } catch (error) {
    console.error('PurchaseEvent GA Event error: ', error);
  }
}

export const BannerImpression = ({ bannerData, eventCommonObj }) => {
  try {
    window["dataLayer"].push({ ecommerce: null });
    gtm({
      event: 'view_promotion',
      ecommerce: {
        items: bannerData
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('BannerImpression GA Event error: ', error);
  }
}

export const BannerClick = ({ bannerData, eventCommonObj }) => {
  try {
    window["dataLayer"].push({ ecommerce: null });

    gtm({
      event: 'select_promotion',
      ecommerce: {
        items: [{
          ...bannerData
        }]
      },
      ...eventCommonObj
    })
  } catch (error) {
    console.error('BannerClick GA Event error: ', error);
  }
}

export const ProductImpression = ({
  products,
  itemListName,
  itemListId,
  itemCategory1 = '',
  itemCategory3 = '',
  productIndex = 0,
  websiteID,
  eventCommonObj
}) => {
  try {
    window["dataLayer"].push({ ecommerce: null });
    const getCoupon = localStorage.getItem('coupon_applied')
    const couponName = getCoupon || '';

    gtm({
      event: 'view_item_list',
      ecommerce: {
        item_list_name: itemListName || 'N/A',
        item_list_id: itemListId || 'N/A',
        items: products?.map((product, index) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: couponName || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: product?.price_range ? product?.price_range?.minimum_price?.regular_price?.value - product?.price_range?.minimum_price?.final_price?.value : product?.mprice - product?.price || product?.regular_price - product?.final_price || 0,
            price: Number(product?.price_range?.minimum_price?.regular_price?.value) || Number(product?.price?.regularPrice?.amount?.value) || Number(product?.mprice) || Number(product?.price) || 'N/A',
            item_category: itemCategory1 || product?.primary_category || product?.variants?.[0]?.product?.primary_category || 'N/A',
            item_category2: product?.product_label || 'N/A',
            item_category3: itemCategory3 || 'N/A',
            item_category4: product?.stock_status || 'N/A',
            item_variant: `${product?.variants?.length > 0 ? product?.variants[0]?.attributes[0]?.label || product?.variants[0]?.product?.size?.label || 'N/A' : product?.size?.label != 'false' ? product?.size?.label || product?.size || 'N/A' : 'N/A'}`, // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`,
            item_list_name: itemListName || 'N/A',
            item_list_id: itemListId || 'N/A',
            index: productIndex + index + 1 || 'N/A',
            quantity: 1
          }
        }),
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('ProductImpression GA Event error: ', error);
  }
}


export const PopUpProductImpression = (
  products,
  websiteID,
  eventCommonObj,
  cartData
) => {
  try {
    window["dataLayer"].push({ ecommerce: null });
    const getCoupon = localStorage.getItem('coupon_applied')
    const couponName = getCoupon || cartData?.applied_coupons?.[0]?.code;
    // const discountAmount = cartData?.prices?.discounts.length > 0 && cartData?.prices?.discounts.reduce((k, v) => (k = k + v.amount.value), 0.0) || cartData?.prices?.discounts.value

    gtm({
      event: 'view_item_list',
      ecommerce: {
        item_list_name: 'gwp_popup',
        item_list_id: 1,
        items: products?.map((product, index) => {
          return {
            item_id: product?.sku || '',
            item_name: product?.name || '',
            affiliation: 'Online Store',
            coupon: couponName || 'N/A',
            currency: websiteID == 1 ? 'INR' : 'USD',
            discount: product?.price_range ? product?.price_range?.minimum_price?.regular_price?.value - product?.price_range?.minimum_price?.final_price?.value : product.mprice - product.price || 0,
            price: Number(product?.price_range?.minimum_price?.regular_price?.value) || Number(product?.price.regularPrice?.amount?.value) || Number(product?.mprice) || Number(product?.price) || 'N/A', // Need to confirm
            item_category: product?.primary_category || 'N/A', // Not getting data ( once primary_category added we can pass this)
            item_category2: product?.product_label || 'N/A', // sub category data not present
            item_category3: 'N/A', // applied filter data not present
            item_category4: product?.stock_status || 'IN_STOCK',
            item_variant: product?.size?.label || product?.size || 'N/A', // As when we click on product it will always select first variant
            item_brand: `${websiteID == 1 ? 'KAMA' : 'KAMA International'}`, // Need to confirm
            item_list_name: 'gwp_popup',
            item_list_id: 1,
            index: index + 1,
            quantity: 1
          }
        })
      },
      ...eventCommonObj
    });
  } catch (error) {
    console.error('ProductImpression GA Event error: ', error);
  }
}

export const getClientId = (cookie) => {
  const string = cookie["_ga"];
  if (string) {
    const splitString = string?.split('.');
    const joinedString = splitString?.slice(2)?.join('.');
    return joinedString;
  } else {
    return cookie["_ga"]
  }

}

export const convertCartItemsToProductFormat = (cartItems) => {
  let productitems = [];
  if (cartItems && cartItems.length > 0) {
    cartItems.map(function (elements, index) {
      productitems[index] = {
        id: '' + elements.product.id,
        sku: elements.product.sku,
        name: elements.product.name,
        quantity: elements.quantity,
        item_price: '' + elements.prices.price.value, // Final Price
        price: elements.prices.price,
        stock_status: "IN_STOCK",
        size: elements?.size || 'N/A',
        category: elements?.product?.primary_category || 'N/A',
        productLabel: elements?.product?.product_label || 'N/A',
        freeproduct: elements?.freeproduct,
        productOriginalPrice: elements?.productprice

      }
    })
  }
  return productitems;
}