import React from "react";
import styles from "./App.module.css";

import NextNprogress from "nextjs-progressbar";

export const App = ({ children }) => {
  return (
    <React.Fragment>
      <div className={styles.app}>
        <NextNprogress
          color="#C4601D"
          startPosition={0}
          stopDelayMs={0}
          height={4}
          options={{ showSpinner: false, easing: "ease" }}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </React.Fragment>
  );
};
