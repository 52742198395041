import { gql } from "@apollo/client";

export const IngredientFragment = gql`
    fragment IngredientFragment on Ingredient{
        id
        title
        slug
        description
        blog_ids
        status
        image
    }
`;