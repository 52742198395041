import { useEffect, useRef, useState } from "react";
import { initializeStore } from "/components/store/store";
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { getReduxSession } from "/lib/sessionHandler";
import { getWebsiteID } from "/lib/common-functions";
import { getLayoutDataSync } from "/lib/services/helpers/cache/getLayoutData";
import { getConfigDataSync } from "/lib/services/helpers/cache/getConfigData";
import { checkCart, logoutUser } from "/lib/magento-api";
import { Menu, MenuIn } from "/lib/menu-json";
import { signOut } from "next-auth/client";
import { getWebsiteURL } from "/lib/services/helpers/getWebsiteURL";

export const useTopHeader = () => {
    const reduxStore = initializeStore();
    const { dispatch } = reduxStore;
    const count = useSelector((state) => state.count);
    const ammayaPoints = useSelector((state) => state.ammayaPoints);
    const [cookie, setCookie] = useCookies(["cartid"]);
    const sessionData = getReduxSession(true);
    const [storecookie, setStoreCookie] = useCookies(["store"]);
    
    const [StaticMenu, setStaticMenu] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [stickClass, setStickClass] = useState('');
    const [homeTicker, sethomeTicker] = useState([]);
    const [session, setSession] = useState([]);
    const [open, setOpen] = useState(false);
    const [websiteurl, setWebsiteurl] = useState("");

    const prevOpen = useRef(open);  
    const anchorRef = useRef(null);

    const website_id = getWebsiteID(storecookie);
    
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);
  
    useEffect(() => {
      getUserCart()
      setWebsiteurl(typeof storecookie.store == "undefined" || storecookie.store == "default" ? "" : "/international");
    }, []);

  
    useEffect(() => {
      setSession(sessionData)
      if (sessionData.user && sessionData.user.customer_group && sessionData.user.amaaya_point) {
        dispatch(
          {
            type: 'AMMAYAPOINTS',
            ammayaPoints: sessionData.user.amaaya_point,
            customer_group: sessionData.user.customer_group
          })
      }
      
    }, [sessionData, ammayaPoints]);
  
    useEffect(() => {
      dispatch({
        type: "SETMENU",
        menu: storeType == 'default' ? Menu.data.categories.items : MenuIn.data.categories.items,
      });
  
      const response = async () => {
        dispatch({
          type: "SETMENU",
          menu: navigationSSR,
        });
      };
      const response2 = async () => {
        dispatch({
          type: "CONFIGDATA",
          configdata: storeData?.storeConfig,
        });
        try {
          sethomeTicker(JSON.parse(storeData?.storeConfig.homepage_banner_tikcer_top))
        } catch (error) {
          console.error(error);
        }
        setStaticMenu(JSON.parse(storeData?.storeConfig.homepage_banner_header_headercmsmenu))
      };
      response();
      response2()
    }, []);
  
    useEffect(() => {
      const scrollHandler = () => {
        window.pageYOffset > 120 ? setStickClass('sticky_header') : setStickClass('')
      };
      window.addEventListener('scroll', scrollHandler);
      scrollHandler(); // Explicit call so that the navbar gets blurred if current page offset is `window.pageYOffset > 20`
  
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      }
    }, []);
  
    function getUserCart() {
      const response = async () => {
        try {
          const tmp = await checkCart();

          if (tmp.message) {
            setCartCount(0)
          } else {
            if (tmp && tmp.data && tmp.data.cart && (tmp.data.cart.total_quantity || tmp.data.cart.total_quantity == 0)) {
              setCartCount(tmp.data.cart.total_quantity)
              dispatch(
                {
                  type: 'INCREMENT',
                  count: tmp?.data?.cart?.total_quantity,
                })
              dispatch(
                {
                  type: 'MINICARTTOTAL',
                  total_price: tmp?.data?.cart?.prices?.grand_total?.value,
                })
            } else {
              setCartCount(0)
            }
          }
        } catch (error) {
          console.log('error',error);
        }
      };
      response();
    }

    function SignoutUser(cookie, e) {
      e.preventDefault();
      setShowLoader(true)
      signOut({ redirect: false })
      const response = async () => {
        try {
          await logoutUser();
          setShowLoader(false)
          setStoreCookie('wishlist_items', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
          setStoreCookie('wishlist_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
          setStoreCookie('wishlist_parent_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
          let url = typeof cookie.store == "undefined" || cookie.store == "default" ? process.env.NEXTAUTH_URL : process.env.NEXTAUTH_URL + "/international/";
          if ((typeof window !== 'undefined')) {
            if (process.browser) {
              //  router.push(url)
              window.location.href = url
            }
          }
        } catch (error) {
          console.log('error',error);
          setShowLoader(false)
        }
  
      };
      response();
    }

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 0,
      },
    };
  
    const prefixUrl = getWebsiteURL(website_id)
    const storeType = typeof storecookie.store == "undefined" || storecookie.store == "default" ? "default" : "international";
    const navigationSSR = typeof window != "undefined" ? window["__nav__"] : getLayoutDataSync(storeType);
    const storeData = typeof window != "undefined" ? window["__store_data__"] : getConfigDataSync(storeType)
    const weburl = (website_id == 2) ? "/international" : ""
    
    let accountLink = weburl + "/customer/account/login"
    accountLink = ((session && session.accessToken) ? weburl + "/customer/account/" : weburl + "/customer/account/login")
    
    let wishlistLink = websiteurl + "/customer/account/login";
    wishlistLink = (session && session.accessToken) ? websiteurl + "/wishlist" : websiteurl + "/customer/account/login";
    
    let salesOrderLink = websiteurl + "/sales/guest/form";
    const eventCommonObj = EventCommonObj(session, storecookie)

    return {
        showLoader,
        responsive,
        homeTicker,
        eventCommonObj,
        stickClass,
        navigationSSR,
        StaticMenu,
        session,
        prefixUrl,
        count,
        anchorRef,
        accountLink,
        storeType,
        salesOrderLink,
        wishlistLink,
        storecookie,
        websiteurl,
        open,
        SignoutUser,
        handleToggle,
        handleClose
    }
  
}