import { gql } from "@apollo/client";

export const GET_PRODUCT_LIST_QUERY = gql`
query ProductsQuery(
    $search: String
    $filters: ProductAttributeFilterInput
    $pageSize: Int = 36
    $currentPage: Int = 1
) {
    storeConfig {
        id
    }
    products(
        search: $search
        filter: $filters
        pageSize: $pageSize
        currentPage: $currentPage
      ) {
        page_info {
          current_page
          total_pages
        }
        aggregations {
          attribute_code
          count
          label
          options {
            label
            value
            count
          }
        }
        items {
          id
          url_key
          url_path
          name
          sku
          stock_status
          product_label
          pwa_oneliner
          gwp_description
          type_id
          volume
          is_free_product
          type_id
          fullsize_product
          rating_summary
          review_count
          primary_category
          bomcode_text
          salableqty{
            label
          }
          size{
            label
          }
          categories{
            id
            name
          }
          short_description {
            html
          }
          small_image {
            url
          }
          live_home_image{
            label
          }
          thumbnail {
            id: url
            url
            label
          }
          categories{
            id
            name
          }
          price_range {
            minimum_price {
              regular_price {
                value
              }
              final_price {
                value
              }
            }
          }
          price {
            regularPrice {
              amount {
                value
                currency
              }
            }
          }
          media_gallery {
            url
            label
            position
            disabled
            ... on ProductVideo {
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
          }
        ... on ConfigurableProduct {
            configurable_options {
              id
              attribute_id
              label
              position
              use_default
              attribute_code
              values {
                value_index
                label
              }
              product_id
            }
            variants {
              product {
                id
                name
                sku
                product_label
                primary_category
                volume
                stock_status
                small_image {
                    url
                }
                size{
                  label
                }
                salableqty{
                  label
                }
                price {
                  regularPrice {
                    amount {
                      value
                    }
                  }
                }
                price_range {
                    minimum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                    }
                }
                attribute_set_id
                ... on PhysicalProductInterface {
                  weight
                }
              }
              attributes {
                label
                code
                value_index
              }
            }
          }
        }
      }
  }
`;