import { resolve } from "path";
import { unlinkSync } from "fs";
import { getCacheByKey, setCacheByKey } from "./getCacheByKey";
import { initializeApollo } from "/lib/apollo-client";
import MENU_QUERY from "/components/layout/Menu.graphql";

export const getLayoutDataSync = (store = 'default') => {
  const key = `navigation_${store}.json`;
  const { data } = getCacheByKey(key);
  return data;
};

export const removeLayoutData = async (store = 'default') => {
  const key = `navigation_${store}.json`;
  const path = resolve("cache", "jsons", key);
  await unlinkSync(path);
  return getLayoutData(store);
};

const getLayoutData = async (store = 'default') => {
  const key = `navigation_${store}.json`;
  const { data } = getCacheByKey(key);
  const apolloClient = initializeApollo(null, store)

  if (!data) {
    const menu_data =  await apolloClient.query({
      query: MENU_QUERY,
    })

    return await setCacheByKey(key, menu_data?.data?.categories?.items, {
      ttl: 86400,
    }).data;
  }

  return data;
};

export default getLayoutData;
