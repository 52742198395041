import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import { useResponseMessageBox } from '/lib/hooks/useResponseMessageBox';

function ResponseMessageBox() {
    const {
        msg,
        message_type,
        handleSnackClose
    } = useResponseMessageBox();

    return (
        msg != '' ? (
            <Snackbar open={(msg != '') ? true : false} autoHideDuration={1500} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={(message_type) ? message_type : 'success'} sx={{ width: '100%' }}>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </Alert>
            </Snackbar>
        ) : null
    )
}

export default ResponseMessageBox