import { useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

export const useIsMobile = (isSsrMobile:any = '') => {
    // Commenting _app.js global context - will remove later TODO
    // const isSsrMobile = useContext(IsSsrMobileContext);
    // const { width } = useWindowDimensions();

    // const [isMobile, SetIsMobile] = useState(isSsrMobile);

    // useEffect(() => {
    //     const isBrowserMobile = !!width && width < 992;
    //     SetIsMobile(isBrowserMobile)
    // },[width]) 

    // return isMobile ||isSsrMobile;

    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(isSsrMobile);

    useEffect(() => {
        const isBrowserMobile = !!width && width < 992;
        setIsMobile(isBrowserMobile)
    },[width]) 

    return isMobile ?? isSsrMobile;
};