import { useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { concatPagination } from '@apollo/client/utilities'
import { parseCookies, setCookie, destroyCookie } from 'nookies'
/**
 * Polyfill Global Variables in Server
 */
if (!process.browser) {
  global.URL = require('url').URL
}

let apolloClient

function createApolloClient(authToken = null, clientstore = null) {
  const cookies = parseCookies(null, "fromClient");
  const store = clientstore == null ? typeof cookies.store == 'undefined' ? "" : cookies.store : clientstore

  const uri = process.browser
    ? new URL('/graphql', location.href)
    : new URL('/graphql', process.env.MAGENTO_URL).href

  let headerArr = { Store: store }
  if (authToken) {
    headerArr.authorization = `Bearer ${authToken}`
  }

  // Todo - Used to check before making any req (for debugging purpose)
  // const customFetch = (uri, options) => {
  //   return fetch(uri, options).then((response) => {
  //     return response.text()
  //   }).then((res) => {
  //     console.log('res', res);
  //     return res;
  //   }).catch((err) => {
  //     console.log('err', err.response)
  //     return err.response
  //   })
  // };

  return new ApolloClient({
    ssrMode: !process.browser,
    credentials: 'include',
    link: new HttpLink({
      uri,
      credentials: 'include', // Additional fetch() options like `credentials` or `headers`
      headers: headerArr,
      // fetch: customFetch
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),

  })
}

export function initializeApollo(initialState = null, storecode = null, isOverride = false) {

  const authToken = initialState == null ? null : initialState.token
  const store = storecode
  let _apolloClient
  if (isOverride) {
    _apolloClient = createApolloClient(authToken, store)
  } else {
    _apolloClient = apolloClient ?? createApolloClient(authToken, store)
  }

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
