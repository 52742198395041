import { resolve } from "path";
import { readFileSync, writeFileSync, existsSync, mkdirSync } from "fs";

export const setCacheByKey = (key, data, meta = {}) => {
  const dir = resolve("cache", "jsons");
  if (!existsSync(dir)) mkdirSync(dir, { recursive: true });
  const path = resolve("cache", "jsons", key);
  const ts = new Date().valueOf();
  const cacheObj = {
    data,
    meta,
    ts,
  };
  writeFileSync(path, JSON.stringify(cacheObj), "utf8");
  return cacheObj;
};

export const getCacheByKey = (key) => {
  const path = resolve("cache", "jsons", key);
  try {
    if (existsSync(path)) {
      var cacheData = JSON.parse(readFileSync(path, "utf8"));
    }
  } catch (e) {}
  if (cacheData) {
    var ttl = cacheData.meta.ttl;
    var cts = new Date().valueOf();
    if (ttl * 100 + cacheData.ts > cts) {
      return cacheData;
    }
  }

  return {
    data: null,
    meta: {},
    ts: 0,
  };
};
