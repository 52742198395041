export const getVideoStatus = (id) => {
    let getVideoClickedArr = JSON.parse(
        localStorage.getItem("video_clicked_arr") || "[]"
    );

    if (getVideoClickedArr.length > 0) {
        const isExist = getVideoClickedArr.includes(id);
        if (isExist) {
            return 'Yes'
        } else {
            return 'No'
        }
    } else {
        return ''
    }
}