const loadedCSS = {};
export function loadCSS(src) {
    if (loadedCSS[src]) {
        return new Promise<void>(function (resolve, reject) {
            resolve();
        });
    }

    return new Promise((resolve, reject) => {
        const css = document.createElement('link');
        css.type = "text/css";
        css.rel = "stylesheet";
        css.href = src;
        css.onload = resolve;
        css.onerror = reject;
        loadedCSS[src] = css;
        document.head.appendChild(css);
    });
}