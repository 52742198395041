import React from 'react';
import classes from '/styles/FormLoader.module.css'

function FormLoader(props) {
  if (props.show) {
    return (
      <div className={classes["lds-ellipsis-main"]}>
        <div className={classes["lds-ellipsis"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default FormLoader;
