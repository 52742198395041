import dynamic from 'next/dynamic';
import { useMobileVerifyModal } from '/lib/hooks/MobileVerifyModal/useMobileVerifyModal';
import FormLoader from '../ui/FormLoader';

const OpenMobileVerifyModal = dynamic(() => import('./openMobileVerifyModal'));

function MobileVerifyModal() {
    const {
        showForm,
        showLoader,
        openVerifyModal,
        accessToken,
        customerMobile,
        setShowLoader,
        setOpenVerifyModal
    } = useMobileVerifyModal()

    return (
        <div style={{ display: showForm ? "flex" : "none" }}>
            <FormLoader show={showLoader} />
            {openVerifyModal ? <OpenMobileVerifyModal
                accessToken={accessToken}
                openVerifyModal={openVerifyModal}
                showForm={showForm}
                customerMobile={customerMobile}
                setShowLoader={setShowLoader}
                setOpenVerifyModal={setOpenVerifyModal}
            /> : null}
        </div>

    )
}

export default MobileVerifyModal