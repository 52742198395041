import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

let store;

const initialState = {
  count: 0,
  cartItems: [],
  viewedProducts: [],
  message: "",
  message_type: "",
  menu: [],
  configdata: [],
  couponCodes: [],
  showMiniCart: false,
  showMiniCartCoupon: false,
  showMiniCartCouponParent: '',
  miniCartLoading: false,
  miniCartCouponCode: '',
  total_price: [],
  session: [],
  sessionData: [],
  showWheel: false,
  showWheelCount: 2,
  ammayaPoints: null,
  customer_group: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESPONSE_MSG":
      return {
        ...state,
        message: action.message,
        message_type: action.message_type,
      };
    case "MINICART":
      return {
        ...state,
        showMiniCart: action.showMiniCart,
      };
    case "WHEEL":
      return {
        ...state,
        showWheel: action.showWheel,
      };
    case "WHEELCOUNT":
      return {
        ...state,
        showWheelCount: action.showWheelCount,
      };
    case "MINICARTCOUPON":
      return {
        ...state,
        showMiniCartCoupon: action.showMiniCartCoupon,
        showMiniCartCouponParent: action.showMiniCartCouponParent,
      };
    case "MINICARTCOUPONDATA":
      return {
        ...state,
        miniCartCouponCode: action.miniCartCouponCode,
      };

    case "MINICARTTOTAL":
      return {
        ...state,
        total_price: action.total_price,
      };
    case "MINICARTLOADINGSTATE":
      return {
        ...state,
        miniCartLoading: action.miniCartLoading,
      };


    case "PAYMENT_DISCOUNT":
      return {
        ...state,
        couponCodes: action.couponCodes,
      };
    case "INCREMENT":
      return {
        ...state,
        count: action.count,
      };
    case "DECREMENT":
      return {
        ...state,
        count: action.count,
      };
    case "ADD_PRODUCT":
      const index = indexSameProduct(state, action);

      if (index !== -1) {
        state.cartItems[index].count = state.cartItems[index].count + 1;

        return {
          ...state,
          cartItems: state.cartItems,
        };
      }
    case "VIEWED_PRODUCT":
      let temp = [];
      let count = 0;
      state.viewedProducts.map(function (item) {
        if (item && action.product) {
          if (item.id == action.product.id) {
            count++
          }
        }
      });
      if (count == 0) {
        temp = [].concat(state.viewedProducts, action.product)
      } else {
        temp = state.viewedProducts
      }
      return {
        ...state,
        viewedProducts: temp,
      };

    case "SETMENU":
      return {
        ...state,
        menu: action.menu,
      };

    case "SESSION":
      return {
        ...state,
        session: action.session,
      };

    case "SESSIONDATA":
      return {
        ...state,
        sessionData: action.sessionData,
      };


    case "AMMAYAPOINTS":
      return {
        ...state,
        ammayaPoints: action.ammayaPoints,
        customer_group: action.customer_group
      };

    case "CONFIGDATA":
      return {
        ...state,
        configdata: action.configdata,
      };

      return {
        ...state,
        cartItems: [
          ...state.cartItems,
          {
            id: action.id,
            name: action.name,
            image: action.image,
            price: action.price,
            count: action.count,
            url_path: action.url_path,
            url_key: action.url_key,
          },
        ],
      };
    case "REMOVE_PRODUCT":
      // find index of product
      state.cartItems.splice(indexSameProduct(state, action), 1);

      return {
        ...state,
        cartItems: state.cartItems,
      };

    case "SET_COUNT":
      // find index and add new count on product count
      const indexItem = indexSameProduct(state, action);
      state.cartItems[indexItem].count = action.count;

      return {
        ...state,
        cartItems: state.cartItems,
      };

    default:
      return state;
  }
};

export const setCount = ({ count }) => ({
  type: "SET_COUNT",
  count,
});

const indexSameProduct = (state, action) => {
  const sameProduct = (product) => product.id === action.id;

  return state.cartItems.findIndex(sameProduct);
};

function initStore(preloadedState = initialState) {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevToolsDevelopmentOnly(applyMiddleware())
  );
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}