import { initializeStore } from "../components/store/store";
import { getSession, useSession } from 'next-auth/client'
import { useSelector, useDispatch } from "react-redux";

const reduxStore = initializeStore();
const { dispatch } = reduxStore;

export function setReduxSession() {

    const sessionD = useSession()
    dispatch({
        type: "SESSIONDATA",
        sessionData: sessionD,
    });
    const [session, loading] = sessionD
    if (!loading && session) {
        dispatch({
            type: "SESSION",
            session: session,
        });
    }
}


export async function setReduxSessionApi(ctx) {

    const session = await getSession(ctx)

    if (session) {
        dispatch({
            type: "SESSION",
            session: session,
        });
    }
}

export function setReduxSessionCustom(session) {

    if (session) {
        dispatch({
            type: "SESSION",
            session: session,
        });
    }
}


export function setReduxSignout() {

    dispatch({
        type: "SESSION",
        session: [],
    });
    dispatch({
        type: "SESSIONDATA",
        session: [],
    });
}

export function getReduxSession(fetch = false) {
    if (fetch) {
        setReduxSession()
    }
    const session = useSelector((state) => state.session);
    return session
}

export function getReduxSessionStale() {
    const session = useSelector((state) => state.sessionData);
    return session
}