import { useEffect, useRef, useState } from "react";
import { getReduxSession, setReduxSignout } from "/lib/sessionHandler";
import { useRouter } from "next/router";
import { initializeStore } from "/components/store/store";
import { Cookies, useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { getLayoutDataSync } from "/lib/services/helpers/cache/getLayoutData";
import { getConfigDataSync } from "/lib/services/helpers/cache/getConfigData";
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { Menu, MenuIn } from "/lib/menu-json";
import { signOut } from "next-auth/client";
import { checkCart, logoutUser } from "/lib/magento-api";

export const useNavbarDesktop = () => {
    const categoryUrlSuffix = ".html";
    const sessionData = getReduxSession(true);
    const router = useRouter();
    const [storecookie, setStoreCookie] = useCookies(["store"]);
    const [cookie, setCookie] = useCookies(["cartid"]);
    const cookiesStore = new Cookies();
    const reduxStore = initializeStore();
    const { pathname } = router;
    const { dispatch } = reduxStore;
    const ammayaPoints = useSelector((state) => state.ammayaPoints);
    const customerAmmayaGroup = useSelector((state) => state.customer_group);
    const count = useSelector((state) => state.count);

    const [showLoader, setShowLoader] = useState(false);
    const [websiteurl, setWebsiteurl] = useState("");
    const [stickClass, setStickClass] = useState('');
    const [miniCartData, setMiniCartData] = useState([]);
    const [crossSellData, setCrossSellData] = useState([]);
    const [staticProductData, setStaticProductData] = useState([]);
    const [homeTicker, sethomeTicker] = useState([]);
    const [StaticMenu, setStaticMenu] = useState([]);
    const [SubcatStaticMenu, setSubcatStaticMenu] = useState([]);
    const [session, setSession] = useState([]);
    const [showMiniBagRender, setShowMiniBagRender] = useState(false);

    const promoArr = useRef(new Array(staticProductData.length || 0).fill(false));
    const catProdArr = useRef(new Array(SubcatStaticMenu.length || 0).fill(false));
    
    const currentURL = router.asPath
    const storeType = typeof storecookie.store == "undefined" || storecookie.store == "default" ? "default" : "international";
    const navigationSSR = typeof window != "undefined" ? window["__nav__"] : getLayoutDataSync(storeType);
    const storeData = typeof window != "undefined" ? window["__store_data__"] : getConfigDataSync(storeType)
    const eventCommonObj = EventCommonObj(session, storecookie)
    const salesOrderLink = `${websiteurl}/sales/guest/form`;
    const accountLink = session?.accessToken ? `${websiteurl}/customer/account/` : `${websiteurl}/customer/account/login`;
    let wishlistLink = `${websiteurl}/customer/account/login`;
    wishlistLink = session?.accessToken ? `${websiteurl}/wishlist` : `${websiteurl}/customer/account/login`;

    useEffect(() => {
        setSession(sessionData)
        if (sessionData.user && sessionData.user.customer_group && sessionData.user.amaaya_point) {
          dispatch(
            {
              type: 'AMMAYAPOINTS',
              ammayaPoints: sessionData.user.amaaya_point,
              customer_group: sessionData.user.customer_group
            })
        }
    
        if (sessionData?.user) {
          localStorage.setItem('login_details', JSON.stringify(sessionData?.user || {}))
        }
    
      }, [sessionData, ammayaPoints]);
    
      useEffect(() => {
        getUserCart()
      }, [])
    
      useEffect(() => {
        dispatch({
          type: "SETMENU",
          menu: storeType == 'default' ? Menu.data.categories.items : MenuIn.data.categories.items,
        });
    
        const response = async () => {
          dispatch({
            type: "SETMENU",
            menu: navigationSSR,
          });
        };
    
        const response2 = async () => {
          setStaticProductData(JSON.parse(storeData?.storeConfig.homepage_banner_megamenu_product_section_megamenu_product_field))
          dispatch({
            type: "CONFIGDATA",
            configdata: storeData?.storeConfig,
          });
          try {
            sethomeTicker(JSON.parse(storeData?.storeConfig?.homepage_banner_tikcer_top))
          } catch (error) {
            console.error(error)
          }
          setStaticMenu(JSON.parse(storeData?.storeConfig?.homepage_banner_header_headercmsmenu))
          if (typeof storeData?.storeConfig?.homepage_banner_megamenu_subcategory_section_subcategory_links != 'undefined' ||
            storeData?.storeConfig?.homepage_banner_megamenu_subcategory_section_subcategory_links != '' ||
            storeData?.storeConfig?.homepage_banner_megamenu_subcategory_section_subcategory_links != null
          ) {
            setSubcatStaticMenu(JSON.parse(storeData?.storeConfig?.homepage_banner_megamenu_subcategory_section_subcategory_links))
          }
        };
    
        response();
        response2()
    
        setWebsiteurl(typeof storecookie.store == "undefined" || storecookie.store == "default" ? "" : "/international");
    
      }, [currentURL]);
    
      useEffect(() => {
        const scrollHandler = () => {
          window.pageYOffset > 10 ? setStickClass('sticky_header') : setStickClass('')
        };
        window.addEventListener('scroll', scrollHandler);
        scrollHandler(); // Explicit call so that the navbar gets blurred if current page offset is `window.pageYOffset > 20`
    
        return () => {
          window.removeEventListener('scroll', scrollHandler);
        }
      }, []);
    
      function SignoutUser(cookie, e) {
        e.preventDefault();
        setShowLoader(true)
        signOut({ redirect: false })

        const response = async () => {
          try {
            await logoutUser();
            setShowLoader(false)
            setReduxSignout()
            setStoreCookie('mobileVerified', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            setStoreCookie('mobileverify', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            setStoreCookie('wishlist_items', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            setStoreCookie('wishlist_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            setStoreCookie('wishlist_parent_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            let url = typeof cookie.store == "undefined" || cookie.store == "default" ? process.env.NEXTAUTH_URL : process.env.NEXTAUTH_URL + "/international/";
            if ((typeof window !== 'undefined')) {
              if (process.browser) {
                //  router.push(url)
                window.location.href = url
              }
            }
          } catch (error) {
            console.log('error', error);
            setShowLoader(false);
          }
        };
        response();
      }
    
      function getUserCart(isMiniCart = false, crossSellUpdateLoader = false) {
        crossSellUpdateLoader && setShowLoader(true)
        if (isMiniCart) {
          dispatch(
            {
              type: 'MINICARTLOADINGSTATE',
              miniCartLoading: true,
            })
        }
        const response = async () => {
          try {
            const tmp = await checkCart();

            crossSellUpdateLoader && setShowLoader(false)

            if (isMiniCart) {
              dispatch(
                {
                  type: 'MINICARTLOADINGSTATE',
                  miniCartLoading: false,
                })
            }
            if (tmp.message) {
              setMiniCartData([])
              setCrossSellData([])
            } else {
      
              if (tmp?.data?.cart?.total_quantity || tmp?.data?.cart?.total_quantity == 0) {
                dispatch(
                  {
                    type: 'INCREMENT',
                    count: tmp?.data?.cart?.total_quantity,
                  })
                dispatch(
                  {
                    type: 'MINICARTTOTAL',
                    total_price: tmp?.data?.cart?.prices?.grand_total.value,
                  })
                setMiniCartData(tmp?.data?.cart)
                setCrossSellData(tmp?.crossSell?.storeConfig);
              } else {
                setMiniCartData([])
                setCrossSellData([])
              }
            }
          } catch (error) {
            console.log('error in get user cart', error);
            setShowLoader(false)
          }
        };
        response();
      }
    
      const handleChange = (e) => {
        setShowLoader(true)
        let webValue = e.target.value
        signOut({ redirect: false })
        cookiesStore.set('store', webValue, { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: true });
        const response = async () => {
          try {
            await logoutUser(webValue);
            setShowLoader(false)
            setReduxSignout()
            setStoreCookie('wishlist_items', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            setStoreCookie('wishlist_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            setStoreCookie('wishlist_parent_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" })
            let url = webValue == "default" ? process.env.NEXTAUTH_URL : process.env.NEXTAUTH_URL + "/international/";
            if ((typeof window !== 'undefined')) {
              if (process.browser) {
                // router.push(url)
                window.location.href = url
              }
            }
          } catch (error) {
            console.log('error in changing store', error);
            setShowLoader(false);
          }
        };
        response();
      };
    
      const handleMiniCart = () => {
        if (pathname.includes('cart')) {
          window.location.reload();
        } else {
          setMiniCartData([])
          setCrossSellData([])
          getUserCart(true)
          dispatch(
            {
              type: 'MINICART',
              showMiniCart: true,
    
            })
          setShowMiniBagRender(true);
        }
      };

      return {
        showLoader,
        homeTicker,
        eventCommonObj,
        stickClass,
        navigationSSR,
        StaticMenu,
        session,
        websiteurl,
        storecookie,
        customerAmmayaGroup,
        ammayaPoints,
        accountLink,
        storeType,
        salesOrderLink,
        count,
        router,
        promoArr,
        cookie,
        SubcatStaticMenu,
        catProdArr,
        categoryUrlSuffix,
        staticProductData,
        showMiniBagRender,
        crossSellData,
        miniCartData,
        wishlistLink,
        getUserCart,
        SignoutUser,
        handleChange,
        handleMiniCart
      }
}