export const Menu = {
  data: {
    categories: {
      total_count: 9,
      items: [
        {
          category_tag: null,
          category_blogs: null,
          id: 243,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "mini",
          url_path: "mini",
          name: "Minis",
          position: 2,
          children_count: "0",
          children: []
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 253,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "new-launches-21",
          url_path: "new-launches-21",
          name: "New Launches",
          position: 3,
          children_count: "0",
          children: []
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 152,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "skin",
          url_path: "skin",
          name: "Skincare",
          position: 4,
          children_count: "19",
          children: [
            {
              id: 155,
              level: 4,
              url_key: "by-concern",
              url_path: "skincare/by-concern",
              name: "By Concern",
              position: 2,
              children_count: "9",
              children: [
                {
                  id: 113,
                  level: 5,
                  url_key: "skin-brightening",
                  url_path: "skincare/by-concern/skin-brightening",
                  name: "Skin Brightening",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 114,
                  level: 5,
                  url_key: "anti-ageing",
                  url_path: "skincare/by-concern/anti-ageing",
                  name: "Anti Ageing",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 115,
                  level: 5,
                  url_key: "anti-acne",
                  url_path: "skincare/by-concern/anti-acne",
                  name: "Anti Acne",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 116,
                  level: 5,
                  url_key: "tan-removal",
                  url_path: "skincare/by-concern/tan-removal",
                  name: "Tan Removal",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 117,
                  level: 5,
                  url_key: "dryness-dehydration",
                  url_path: "skincare/by-concern/dryness-dehydration",
                  name: "Dryness & Dehydration",
                  position: 5,
                  children_count: "0"
                },
                {
                  id: 118,
                  level: 5,
                  url_key: "oil-control",
                  url_path: "skincare/by-concern/oil-control",
                  name: "Oil Control",
                  position: 6,
                  children_count: "0"
                },
                {
                  id: 256,
                  level: 5,
                  url_key: "dark-circles-treatment",
                  url_path: "skin/by-concern/dark-circles-treatment",
                  name: "Dark Circles Treatment",
                  position: 9,
                  children_count: "0"
                },
                {
                  id: 261,
                  level: 5,
                  url_key: "night-routine",
                  url_path: "skin/by-concern/night-routine",
                  name: "Night Routine",
                  position: 10,
                  children_count: "0"
                }
              ]
            },
            {
              id: 153,
              level: 4,
              url_key: "by-category",
              url_path: "skincare/by-category",
              name: "By Category",
              position: 1,
              children_count: "8",
              children: [
                {
                  id: 108,
                  level: 5,
                  url_key: "cleansers-scrubs",
                  url_path: "skincare/by-category/cleansers-scrubs",
                  name: "Face Wash & Cleansers",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 159,
                  level: 5,
                  url_key: "creams-moisturizers",
                  url_path: "skincare/by-category/creams-moisturizers",
                  name: "Face Cream & Moisturizers",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 109,
                  level: 5,
                  url_key: "toners-mists",
                  url_path: "skincare/by-category/toners-mists",
                  name: "Toners & Mists",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 110,
                  level: 5,
                  url_key: "masks",
                  url_path: "skincare/by-category/masks",
                  name: "Masks",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 111,
                  level: 5,
                  url_key: "serums-treatments",
                  url_path: "skincare/by-category/serums-treatments",
                  name: "Serums & Treatments",
                  position: 5,
                  children_count: "0"
                },
                {
                  id: 112,
                  level: 5,
                  url_key: "lip-care",
                  url_path: "skincare/by-category/lip-care",
                  name: "Lip Care",
                  position: 6,
                  children_count: "0"
                },
                {
                  id: 258,
                  level: 5,
                  url_key: "face-oils",
                  url_path: "skin/by-category/face-oils",
                  name: "Face Oils",
                  position: 7,
                  children_count: "0"
                },
                {
                  id: 259,
                  level: 5,
                  url_key: "face-scrubs",
                  url_path: "skin/by-category/face-scrubs",
                  name: "Face Scrubs",
                  position: 8,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 90,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "hair",
          url_path: "hair",
          name: "Haircare",
          position: 5,
          children_count: "13",
          children: [
            {
              id: 120,
              level: 4,
              url_key: "by-concern",
              url_path: "haircare/by-concern",
              name: "By Concern",
              position: 2,
              children_count: "5",
              children: [
                {
                  id: 125,
                  level: 5,
                  url_key: "hair-loss-thinning",
                  url_path: "haircare/by-concern/hair-loss-thinning",
                  name: "Hair Loss & Thinning",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 126,
                  level: 5,
                  url_key: "dandruff",
                  url_path: "haircare/by-concern/dandruff",
                  name: "Dandruff",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 127,
                  level: 5,
                  url_key: "dryness-damage",
                  url_path: "haircare/by-concern/dryness-damage",
                  name: "Dryness & Damage",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 128,
                  level: 5,
                  url_key: "premature-graying",
                  url_path: "haircare/by-concern/premature-graying",
                  name: "Premature Graying",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 129,
                  level: 5,
                  url_key: "color-protection",
                  url_path: "haircare/by-concern/color-protection",
                  name: "Color Protection",
                  position: 5,
                  children_count: "0"
                }
              ]
            },
            {
              id: 119,
              level: 4,
              url_key: "by-category",
              url_path: "haircare/by-category",
              name: "By Category",
              position: 1,
              children_count: "6",
              children: [
                {
                  id: 121,
                  level: 5,
                  url_key: "cleansers",
                  url_path: "haircare/by-category/cleansers",
                  name: "Hair Cleansers",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 122,
                  level: 5,
                  url_key: "conditioners",
                  url_path: "haircare/by-category/conditioners",
                  name: "Hair Conditioners",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 123,
                  level: 5,
                  url_key: "oils-treatments",
                  url_path: "haircare/by-category/oils-treatments",
                  name: "Oils & Treatments",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 124,
                  level: 5,
                  url_key: "organic-hair-color",
                  url_path: "haircare/by-category/organic-hair-color",
                  name: "Organic Hair Color",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 276,
                  level: 5,
                  url_key: "hair-masks",
                  url_path: "hair/by-category/hair-masks",
                  name: "Hair Masks",
                  position: 6,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 91,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "bath-body",
          url_path: "bath-body",
          name: "Bath & Body",
          position: 6,
          children_count: "19",
          children: [
            {
              id: 130,
              level: 4,
              url_key: "by-category",
              url_path: "bath-body/by-category",
              name: "By Category",
              position: 1,
              children_count: "12",
              children: [
                {
                  id: 132,
                  level: 5,
                  url_key: "cleansers-salts",
                  url_path: "bath-body/by-category/cleansers-salts",
                  name: "Body Cleansers",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 133,
                  level: 5,
                  url_key: "handmade-soaps",
                  url_path: "bath-body/by-category/handmade-soaps",
                  name: "Handmade Soaps",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 134,
                  level: 5,
                  url_key: "scrubs-exfoliators",
                  url_path: "bath-body/by-category/scrubs-exfoliators",
                  name: "Body Scrubs & Exfoliators",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 135,
                  level: 5,
                  url_key: "moisturizers",
                  url_path: "bath-body/by-category/moisturizers",
                  name: "Body Moisturizers",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 255,
                  level: 5,
                  url_key: "body-butters",
                  url_path: "bath-body/by-category/body-butters",
                  name: "Body Butters",
                  position: 5,
                  children_count: "0"
                },
                {
                  id: 136,
                  level: 5,
                  url_key: "mists",
                  url_path: "bath-body/by-category/mists",
                  name: "Body Mists",
                  position: 6,
                  children_count: "0"
                },
                {
                  id: 137,
                  level: 5,
                  url_key: "treatment-oils-balms",
                  url_path: "bath-body/by-category/treatment-oils-balms",
                  name: "Treatment Oils & Balms",
                  position: 7,
                  children_count: "0"
                },
                {
                  id: 138,
                  level: 5,
                  url_key: "bath-body-oils",
                  url_path: "bath-body/by-category/bath-body-oils",
                  name: "Bath & Body Oils",
                  position: 8,
                  children_count: "0"
                },
                {
                  id: 139,
                  level: 5,
                  url_key: "certified-organic-oils",
                  url_path: "bath-body/by-category/certified-organic-oils",
                  name: "Certified Organic Oils",
                  position: 9,
                  children_count: "0"
                },
                {
                  id: 140,
                  level: 5,
                  url_key: "hand-care",
                  url_path: "bath-body/by-category/hand-care",
                  name: "Hand Care",
                  position: 10,
                  children_count: "0"
                },
                {
                  id: 141,
                  level: 5,
                  url_key: "foot-care",
                  url_path: "bath-body/by-category/foot-care",
                  name: "Foot Care",
                  position: 11,
                  children_count: "0"
                },
                {
                  id: 257,
                  level: 5,
                  url_key: "massage-oils",
                  url_path: "bath-body/by-category/massage-oils",
                  name: "Massage Oils",
                  position: 12,
                  children_count: "0"
                }
              ]
            },
            {
              id: 131,
              level: 4,
              url_key: "by-concern",
              url_path: "bath-body/by-concern",
              name: "By Concern",
              position: 2,
              children_count: "5",
              children: [
                {
                  id: 142,
                  level: 5,
                  url_key: "brightening-tan-removal",
                  url_path: "bath-body/by-concern/brightening-tan-removal",
                  name: "Brightening & Tan Removal",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 143,
                  level: 5,
                  url_key: "dryness-dehydration",
                  url_path: "bath-body/by-concern/dryness-dehydration",
                  name: "Dryness & Dehydration",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 144,
                  level: 5,
                  url_key: "firming-stretch-marks",
                  url_path: "bath-body/by-concern/firming-stretch-marks",
                  name: "Firming & Stretch Marks",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 145,
                  level: 5,
                  url_key: "stress-relief-rejuvenation",
                  url_path: "bath-body/by-concern/stress-relief-rejuvenation",
                  name: "Stress Relief & Rejuvenation",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 166,
                  level: 5,
                  url_key: "pain-relief",
                  url_path: "bath-body/by-concern/pain-relief",
                  name: "Pain Relief Oil & Balm",
                  position: 5,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 94,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "men",
          url_path: "men",
          name: "Men's",
          position: 7,
          children_count: "6",
          children: [
            {
              id: 268,
              level: 4,
              url_key: "by-category",
              url_path: "men/by-category",
              name: "By Category",
              position: 1,
              children_count: "4",
              children: [
                {
                  id: 146,
                  level: 5,
                  url_key: "men-s-skincare",
                  url_path: "men/by-category/men-s-skincare",
                  name: "Men's Skincare",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 149,
                  level: 5,
                  url_key: "hair-care",
                  url_path: "men/by-category/hair-care",
                  name: "Men's Hair Care",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 150,
                  level: 5,
                  url_key: "bath-body",
                  url_path: "men/by-category/bath-body",
                  name: "Men's Bath & Body ",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 147,
                  level: 5,
                  url_key: "men-s-gifting",
                  url_path: "men/by-category/men-s-gifting",
                  name: "Men's Gifting",
                  position: 4,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 92,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "wellness",
          url_path: "wellness",
          name: "Wellness",
          position: 8,
          children_count: "4",
          children: [
            {
              id: 278,
              level: 4,
              url_key: "by-category",
              url_path: "wellness/by-category",
              name: "By Category",
              position: 1,
              children_count: "3",
              children: [
                {
                  id: 160,
                  level: 5,
                  url_key: "essential-oils",
                  url_path: "wellness/by-category/essential-oils",
                  name: "Essential oils",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 161,
                  level: 5,
                  url_key: "candles",
                  url_path: "wellness/by-category/candles",
                  name: "Candles",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 162,
                  level: 5,
                  url_key: "incense-sticks",
                  url_path: "wellness/by-category/incense-sticks",
                  name: "Incense sticks",
                  position: 3,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 72,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "gifting",
          url_path: "gifting",
          name: "Gifting",
          position: 9,
          children_count: "19",
          children: [
            {
              id: 99,
              level: 4,
              url_key: "gift-ideas",
              url_path: "gifting/gift-ideas",
              name: "Gift Ideas",
              position: 2,
              children_count: "4",
              children: [
                {
                  id: 217,
                  level: 5,
                  url_key: "gifts-for-her",
                  url_path: "gifting/gift-ideas/gifts-for-her",
                  name: "Gifts for Her",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 216,
                  level: 5,
                  url_key: "gifts-for-him",
                  url_path: "gifting/gift-ideas/gifts-for-him",
                  name: "Gifts for Him",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 215,
                  level: 5,
                  url_key: "gifts-under-2000",
                  url_path: "gifting/gift-ideas/gifts-under-2000",
                  name: "Gifts Under 2000",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 97,
                  level: 5,
                  url_key: "gifts-under-3000",
                  url_path: "gifting/gift-ideas/gifts-under-3000",
                  name: "Gifts Under 3000",
                  position: 4,
                  children_count: "0"
                }
              ]
            },
            {
              id: 98,
              level: 4,
              url_key: "gifting-by-category",
              url_path: "gifting/gifting-by-category",
              name: "Gifts By Category",
              position: 1,
              children_count: "4",
              children: [
                {
                  id: 214,
                  level: 5,
                  url_key: "gift-sets",
                  url_path: "gifting/gifting-by-category/gift-sets",
                  name: "Gift Sets",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 13,
                  level: 5,
                  url_key: "regime",
                  url_path: "gifting/gifting-by-category/regime",
                  name: "Regime & Combos",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 210,
                  level: 5,
                  url_key: "corporate-gifting",
                  url_path: "corporate-gifting",
                  name: "Corporate Gifting",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 167,
                  level: 5,
                  url_key: "e-gift-card",
                  url_path: "gifting/gifting-by-category/e-gift-card",
                  name: "E-Gift Card",
                  position: 4,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 158,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "best-seller",
          url_path: "best-seller",
          name: "Bestsellers",
          position: 10,
          children_count: "0",
          children: []
        }
      ],
      page_info: {
        current_page: 1,
        page_size: 20,
        total_pages: 1
      }
    }
  }
}


export const MenuIn = {
  data: {
    categories: {
      total_count: 9,
      items: [
        {
          category_tag: null,
          category_blogs: null,
          id: 243,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "mini",
          url_path: "mini",
          name: "Minis",
          position: 2,
          children_count: "0",
          children: []
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 253,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "new-launches-21",
          url_path: "new-launches-21",
          name: "New Launches",
          position: 3,
          children_count: "0",
          children: []
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 152,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "skin",
          url_path: "skin",
          name: "Skincare",
          position: 4,
          children_count: "19",
          children: [
            {
              id: 155,
              level: 4,
              url_key: "by-concern",
              url_path: "skin/by-concern",
              name: "By Concern",
              position: 2,
              children_count: "9",
              children: [
                {
                  id: 113,
                  level: 5,
                  url_key: "skin-brightening",
                  url_path: "skin/by-concern/skin-brightening",
                  name: "Skin Brightening",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 114,
                  level: 5,
                  url_key: "anti-ageing",
                  url_path: "skin/by-concern/anti-ageing",
                  name: "Anti Ageing",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 115,
                  level: 5,
                  url_key: "anti-acne",
                  url_path: "skin/by-concern/anti-acne",
                  name: "Anti Acne",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 116,
                  level: 5,
                  url_key: "tan-removal",
                  url_path: "skin/by-concern/tan-removal",
                  name: "Tan Removal",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 117,
                  level: 5,
                  url_key: "dryness-dehydration",
                  url_path: "skin/by-concern/dryness-dehydration",
                  name: "Dryness & Dehydration",
                  position: 5,
                  children_count: "0"
                },
                {
                  id: 118,
                  level: 5,
                  url_key: "oil-control",
                  url_path: "skin/by-concern/oil-control",
                  name: "Oil Control",
                  position: 6,
                  children_count: "0"
                },
                {
                  id: 261,
                  level: 5,
                  url_key: "night-routine",
                  url_path: "skin/by-concern/night-routine",
                  name: "Night Routine",
                  position: 10,
                  children_count: "0"
                }
              ]
            },
            {
              id: 153,
              level: 4,
              url_key: "by-category",
              url_path: "skin/by-category",
              name: "By Category",
              position: 1,
              children_count: "8",
              children: [
                {
                  id: 108,
                  level: 5,
                  url_key: "cleansers-scrubs",
                  url_path: "skin/by-category/cleansers-scrubs",
                  name: "Face Wash & Cleansers",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 159,
                  level: 5,
                  url_key: "creams-moisturizers",
                  url_path: "skin/by-category/creams-moisturizers",
                  name: "Face Cream & Moisturizers",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 109,
                  level: 5,
                  url_key: "toners-mists",
                  url_path: "skin/by-category/toners-mists",
                  name: "Toners & Mists",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 110,
                  level: 5,
                  url_key: "masks",
                  url_path: "skin/by-category/masks",
                  name: "Masks",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 111,
                  level: 5,
                  url_key: "serums-treatments",
                  url_path: "skin/by-category/serums-treatments",
                  name: "Serums & Treatments",
                  position: 5,
                  children_count: "0"
                },
                {
                  id: 112,
                  level: 5,
                  url_key: "lip-care",
                  url_path: "skin/by-category/lip-care",
                  name: "Lip Care",
                  position: 6,
                  children_count: "0"
                },
                {
                  id: 259,
                  level: 5,
                  url_key: "face-scrubs",
                  url_path: "skin/by-category/face-scrubs",
                  name: "Face Scrubs",
                  position: 8,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 90,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "hair",
          url_path: "hair",
          name: "Haircare",
          position: 5,
          children_count: "13",
          children: [
            {
              id: 120,
              level: 4,
              url_key: "by-concern",
              url_path: "hair/by-concern",
              name: "By Concern",
              position: 2,
              children_count: "5",
              children: [
                {
                  id: 125,
                  level: 5,
                  url_key: "hair-loss-thinning",
                  url_path: "hair/by-concern/hair-loss-thinning",
                  name: "Hair Loss & Thinning",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 126,
                  level: 5,
                  url_key: "dandruff",
                  url_path: "hair/by-concern/dandruff",
                  name: "Dandruff",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 127,
                  level: 5,
                  url_key: "dryness-damage",
                  url_path: "hair/by-concern/dryness-damage",
                  name: "Dryness & Damage",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 128,
                  level: 5,
                  url_key: "premature-graying",
                  url_path: "hair/by-concern/premature-graying",
                  name: "Premature Graying",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 129,
                  level: 5,
                  url_key: "color-protection",
                  url_path: "hair/by-concern/color-protection",
                  name: "Color Protection",
                  position: 5,
                  children_count: "0"
                }
              ]
            },
            {
              id: 119,
              level: 4,
              url_key: "by-category",
              url_path: "hair/by-category",
              name: "By Category",
              position: 1,
              children_count: "6",
              children: [
                {
                  id: 121,
                  level: 5,
                  url_key: "cleansers",
                  url_path: "hair/by-category/cleansers",
                  name: "Hair Cleansers",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 122,
                  level: 5,
                  url_key: "conditioners",
                  url_path: "hair/by-category/conditioners",
                  name: "Hair Conditioners",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 123,
                  level: 5,
                  url_key: "oils-treatments",
                  url_path: "hair/by-category/oils-treatments",
                  name: "Oils & Treatments",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 276,
                  level: 5,
                  url_key: "hair-masks",
                  url_path: "hair/by-category/hair-masks",
                  name: "Hair Masks",
                  position: 6,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 91,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "bath-body",
          url_path: "bath-body",
          name: "Bath & Body",
          position: 6,
          children_count: "19",
          children: [
            {
              id: 130,
              level: 4,
              url_key: "by-category",
              url_path: "bath-body/by-category",
              name: "By Category",
              position: 1,
              children_count: "12",
              children: [
                {
                  id: 132,
                  level: 5,
                  url_key: "cleansers-salts",
                  url_path: "bath-body/by-category/cleansers-salts",
                  name: "Body Cleansers",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 133,
                  level: 5,
                  url_key: "handmade-soaps",
                  url_path: "bath-body/by-category/handmade-soaps",
                  name: "Handmade Soaps",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 134,
                  level: 5,
                  url_key: "scrubs-exfoliators",
                  url_path: "bath-body/by-category/scrubs-exfoliators",
                  name: "Body Scrubs & Exfoliators",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 135,
                  level: 5,
                  url_key: "moisturizers",
                  url_path: "bath-body/by-category/moisturizers",
                  name: "Body Moisturizers",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 255,
                  level: 5,
                  url_key: "body-butters",
                  url_path: "bath-body/by-category/body-butters",
                  name: "Body Butters",
                  position: 5,
                  children_count: "0"
                },
                {
                  id: 136,
                  level: 5,
                  url_key: "mists",
                  url_path: "bath-body/by-category/mists",
                  name: "Body Mists",
                  position: 6,
                  children_count: "0"
                },
                {
                  id: 137,
                  level: 5,
                  url_key: "treatment-oils-balms",
                  url_path: "bath-body/by-category/treatment-oils-balms",
                  name: "Treatment Oils & Balms",
                  position: 7,
                  children_count: "0"
                },
                {
                  id: 138,
                  level: 5,
                  url_key: "bath-body-oils",
                  url_path: "bath-body/by-category/bath-body-oils",
                  name: "Bath & Body Oils",
                  position: 8,
                  children_count: "0"
                },
                {
                  id: 139,
                  level: 5,
                  url_key: "certified-organic-oils",
                  url_path: "bath-body/by-category/certified-organic-oils",
                  name: "Certified Organic Oils",
                  position: 9,
                  children_count: "0"
                },
                {
                  id: 140,
                  level: 5,
                  url_key: "hand-care",
                  url_path: "bath-body/by-category/hand-care",
                  name: "Hand Care",
                  position: 10,
                  children_count: "0"
                },
                {
                  id: 141,
                  level: 5,
                  url_key: "foot-care",
                  url_path: "bath-body/by-category/foot-care",
                  name: "Foot Care",
                  position: 11,
                  children_count: "0"
                },
                {
                  id: 257,
                  level: 5,
                  url_key: "massage-oils",
                  url_path: "bath-body/by-category/massage-oils",
                  name: "Massage Oils",
                  position: 12,
                  children_count: "0"
                }
              ]
            },
            {
              id: 131,
              level: 4,
              url_key: "by-concern",
              url_path: "bath-body/by-concern",
              name: "By Concern",
              position: 2,
              children_count: "5",
              children: [
                {
                  id: 142,
                  level: 5,
                  url_key: "brightening-tan-removal",
                  url_path: "bath-body/by-concern/brightening-tan-removal",
                  name: "Brightening & Tan Removal",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 143,
                  level: 5,
                  url_key: "dryness-dehydration",
                  url_path: "bath-body/by-concern/dryness-dehydration",
                  name: "Dryness & Dehydration",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 144,
                  level: 5,
                  url_key: "firming-stretch-marks",
                  url_path: "bath-body/by-concern/firming-stretch-marks",
                  name: "Firming & Stretch Marks",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 145,
                  level: 5,
                  url_key: "stress-relief-rejuvenation",
                  url_path: "bath-body/by-concern/stress-relief-rejuvenation",
                  name: "Stress Relief & Rejuvenation",
                  position: 4,
                  children_count: "0"
                },
                {
                  id: 166,
                  level: 5,
                  url_key: "pain-relief",
                  url_path: "bath-body/by-concern/pain-relief",
                  name: "Pain Relief Oil & Balm",
                  position: 5,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 94,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "men",
          url_path: "men",
          name: "Men",
          position: 7,
          children_count: "6",
          children: [
            {
              id: 268,
              level: 4,
              url_key: "by-category",
              url_path: "men/by-category",
              name: "By Category",
              position: 1,
              children_count: "4",
              children: [
                {
                  id: 146,
                  level: 5,
                  url_key: "men-s-skincare",
                  url_path: "men/by-category/men-s-skincare",
                  name: "Men's Skincare",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 149,
                  level: 5,
                  url_key: "hair-care",
                  url_path: "men/by-category/hair-care",
                  name: "Men's Hair Care",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 150,
                  level: 5,
                  url_key: "bath-body",
                  url_path: "men/by-category/bath-body",
                  name: "Men's Bath & Body ",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 147,
                  level: 5,
                  url_key: "shaving",
                  url_path: "men/by-category/shaving",
                  name: "Men's Gifting",
                  position: 4,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 92,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "wellness",
          url_path: "wellness",
          name: "Wellness",
          position: 8,
          children_count: "4",
          children: [
            {
              id: 278,
              level: 4,
              url_key: "by-category",
              url_path: "wellness/by-category",
              name: "By Category",
              position: 1,
              children_count: "3",
              children: [
                {
                  id: 161,
                  level: 5,
                  url_key: "candles",
                  url_path: "wellness/by-category/candles",
                  name: "Candles",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 162,
                  level: 5,
                  url_key: "incense-sticks",
                  url_path: "wellness/by-category/incense-sticks",
                  name: "Incense sticks",
                  position: 3,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 72,
          level: 3,
          display_mode: "PRODUCTS_AND_PAGE",
          url_key: "gifting",
          url_path: "gifting",
          name: "Gifting",
          position: 9,
          children_count: "19",
          children: [
            {
              id: 99,
              level: 4,
              url_key: "retail",
              url_path: "gifting/retail",
              name: "Gift Ideas",
              position: 2,
              children_count: "4",
              children: [
                {
                  id: 217,
                  level: 5,
                  url_key: "gifts-for-her",
                  url_path: "gifting/retail/gifts-for-her",
                  name: "Gifts for Her",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 216,
                  level: 5,
                  url_key: "gifts-for-him",
                  url_path: "gifting/retail/gifts-for-him",
                  name: "Gifts for Him",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 215,
                  level: 5,
                  url_key: "gifts-under-2000",
                  url_path: "gifting/gift-ideas/gifts-under-2000",
                  name: "Gifts Under $50",
                  position: 3,
                  children_count: "0"
                },
                {
                  id: 97,
                  level: 5,
                  url_key: "gifts-under-100",
                  url_path: "gifting/retail/gifts-under-100",
                  name: "Gifts Under $100",
                  position: 4,
                  children_count: "0"
                }
              ]
            },
            {
              id: 98,
              level: 4,
              url_key: "gifting-by-category",
              url_path: "gifting/gifting-by-category",
              name: "Gifts By Category",
              position: 1,
              children_count: "4",
              children: [
                {
                  id: 214,
                  level: 5,
                  url_key: "gift-sets",
                  url_path: "gifting/gifting-by-category/gift-sets",
                  name: "Gift Sets",
                  position: 1,
                  children_count: "0"
                },
                {
                  id: 13,
                  level: 5,
                  url_key: "regime",
                  url_path: "gifting/gifting-by-category/regime",
                  name: "Regime & Combos",
                  position: 2,
                  children_count: "0"
                },
                {
                  id: 210,
                  level: 5,
                  url_key: "corporate-gifting",
                  url_path: "corporate-gifting",
                  name: "Corporate Gifting",
                  position: 3,
                  children_count: "0"
                }
              ]
            }
          ]
        },
        {
          category_tag: null,
          category_blogs: null,
          id: 158,
          level: 3,
          display_mode: "PRODUCTS",
          url_key: "best-seller",
          url_path: "best-seller",
          name: "Bestsellers",
          position: 10,
          children_count: "0",
          children: []
        }
      ],
      page_info: {
        current_page: 1,
        page_size: 20,
        total_pages: 1
      }
    }
  }
}