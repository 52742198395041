import { getClientId } from "../../ga";

export const EventCommonObj = (session, cookie) => {
    const checkStatus = typeof window != 'undefined' && JSON.parse(localStorage.getItem('login_details'));

    if (!checkStatus) {
        return {
            user_type: 'new_user',
            user_status: 'guest',
            customer_type: 'new_customer',
            clientID: getClientId(cookie) || 'N/A',
            userID: 'N/A',
            subscription_status: 'non_subscribed'
        }
    } else {
        return {
            user_type: 'returning_user',
            user_status: 'loggedin',
            customer_type: 'existing_customer',
            clientID: getClientId(cookie) || 'N/A',
            userID: checkStatus?.customerid || 'N/A',
            subscription_status: checkStatus?.is_subscribed ? 'subscribed' : 'non_subscribed'
        }
    }
}