import { useRouter } from "next/router";
import { getWebsiteURL } from "./services/helpers/getWebsiteURL";

export function getWebsiteID(cookie) {
  let website_id = ((cookie.store) ? ((cookie.store == "default") ? 1 : 2) : 1)
  return website_id
}
export function formatDate(date) {
  const newDate = new Date(date);
  const month = newDate.toLocaleString('default', { month: 'long' });
  return `${newDate.getDate()} ${month} ${newDate.getFullYear()}`;
}

export function freshChat() {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `window.fcSettings = {
        token: "c792a4c7-8dd9-4d1a-b781-24652895036b",
        host: "https://wchat.in.freshchat.com",
        config: {
          headerProperty: {
            hideChatButton: true
          }
        },
        onInit: function() {
          window.fcWidget.on("widget:loaded",function() {
            document.getElementById('custom_fc_button').style.visibility = 'visible';
            window.fcWidget.on("unreadCount:notify", function(resp) {
              test = resp;
            });
            window.fcWidget.on("widget:closed", function() {
              document.getElementById('fc_frame').style.visibility = 'hidden';
              document.getElementById('open_fc_widget').style.visibility = 'visible';
            });
            window.fcWidget.on("widget:opened", function(resp) {
              document.getElementById('open_fc_widget').style.visibility = 'hidden';
            });
          });
        }
      };
      var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;
s.src="https://wchat.freshchat.com/js/widget.js";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.write("<div id='zsiqwidget'></div>");
var openWidget = function() {
  document.getElementById('fc_frame').style.visibility = 'visible';
  window.fcWidget.open();
};`,
      }}
    >

    </div>
  )
}

export function readTime(content) {
  if (!content) {
    return 0
  }
  const WPS = 275 / 60

  var images = 0
  const regex = /\w/

  let words = content.split(' ').filter((word) => {
    if (word.includes('<img')) {
      images += 1
    }
    return regex.test(word)
  }).length

  var imageAdjust = images * 4
  var imageSecs = 0
  var imageFactor = 12

  while (images) {
    imageSecs += imageFactor
    if (imageFactor > 3) {
      imageFactor -= 1
    }
    images -= 1
  }

  const minutes = Math.ceil(((words - imageAdjust) / WPS + imageSecs) / 60)

  return minutes
}

export function removeExcerpt(content) {
  var new_str = content.replace('[&hellip;]', '');
  return new_str;
}
export async function getInsta() {
  try {
    // const headers = { 'Content-Type': 'application/json' };
    const res = await fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink&limit=${process.env.INSTA_LIMIT}&access_token=${process.env.INSTA_TOKEN}`);
    const data = await res.json();
    return data;
  } catch (error) {
    return error
  }
}

export async function fetchInstagramPost() {
  let res = [];
  try {
    axios
      .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${process.env.INSTA_LIMIT}&access_token=${process.env.INSTA_TOKEN}`)
      .then((resp) => {
        res = resp.data.data;
        // setFeedsData(resp.data.data)
      })
  } catch (err) {
    console.log('error', err)
  }
  return res
}


export async function createUser(username, password) {
  const response = await fetch("/api/login", {
    method: "POST",
    body: JSON.stringify({ username, password }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

export function checkLogin(session, loading, website_id = 1) {
  let prefixLink = getWebsiteURL(website_id)
  const router = useRouter();
  if ((typeof window !== 'undefined' && loading) || !session || !session.accessToken) {
    if (process.browser) {
      router.push(prefixLink + '/customer/account/login')
    }
  }
}

export function paytmRedirect(prefixLink, orderid) {
  const router = useRouter();
  if ((typeof window !== 'undefined')) {
    if (process.browser) {
      router.push({
        pathname: "/checkout/success",
        query: { orderid: orderid }
      }, prefixLink + "checkout/success");
    }
  }
}

export async function getToken(username, password) {
  const response = await fetch(process.env.NEXTAUTH_URL + "/api/login", {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: { "Content-Type": "application/json" }
  })

  const data = await response.json();
  return data
}

export async function addWishlist(wishlistId, parentSku, sku, accessToken) {
  try {
    const res = await fetch(process.env.NEXTAUTH_URL + "/api/add-wishlist", {
      body: JSON.stringify({ wishlistId: wishlistId, parent_sku: parentSku, sku: sku, accessToken: accessToken }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    const data = await res.json();
    if (!res.ok) {
      return data;
    }
    return data;
  } catch (error) {
    return error
  }
}


